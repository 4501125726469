import { MainMenuList } from 'lib/values/portfolioValues';
import Business from 'pages/Business';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function MainMenu() {
  const [businessMenu, setBusinessMenu] = useState(false);

  const openBusinessMenu = () => {
    setBusinessMenu(true);
  };

  return (
    <div className="mainMenu">
      <div className="fixedBg">
        <img src="/img/bg_white_line.png" alt="line bg" />
      </div>
      <div className="menuWrapper">
        {MainMenuList.map((menu, idx) =>
          menu.name !== 'Business' ? (
            <Link to={menu.url} className="mainMenuLink menuBtnStyle" key={menu.url}>
              {menu.name}
            </Link>
          ) : (
            <div className="businessMenu" key={menu.name}>
              <a className="menuBtnStyle" onClick={openBusinessMenu}>
                {menu.name}
                {businessMenu && <Business />}
              </a>
            </div>
          )
        )}
        <div className="menuFooter">
          <div className="info">
            <p>(+82)2-3498-0800</p>
            <p>
              FM Building, 34, Banpo-daero 22-gil,
              <br />
              Seocho-gu, Seoul, 00649, Korea
            </p>
          </div>
          <div className="snsWrapper">
            <a href="https://www.instagram.com/feelxmove/" target="_blank">
              Instagram
              <span>
                <img src="/img/link.svg" alt="link" />
              </span>
            </a>
            <a href="https://www.facebook.com/fmcommunications/" target="_blank">
              Facebook
              <span>
                <img src="/img/link.svg" alt="link" />
              </span>
            </a>
            <a href="https://www.youtube.com/channel/UCjP5KRKZRKjo4Ii-NSnnT1w" target="_blank">
              Youtube
              <span>
                <img src="/img/link.svg" alt="link" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainMenu;
