import BusinessHeader from 'components/business/BusinessHeader';
import BusinessTextImage from 'components/business/BusinessTextImage';
import BusinessArrow from 'components/common/BusinessArrow';
import { itSolutionInfo } from 'lib/values/businessInfo';
import React from 'react';

function ITSolution() {
  return (
    <div className="itSolutionWrapper scrolling">
      <BusinessHeader
        target="itSolution"
        headerTitle={itSolutionInfo.headerTitle}
        subTitle={itSolutionInfo.subTitle}
        contents={itSolutionInfo.contents}
      />
      <div className="contentSection">
        {itSolutionInfo.mainContents.map((content, idx) => (
          <BusinessTextImage
            type={content.type}
            idx={idx + 1}
            first={content.first}
            second={content.second}
            target="itSolution"
          />
        ))}
      </div>
    </div>
  );
}

export default ITSolution;
