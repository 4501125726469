import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from 'store';
import { login } from 'store/user/actions';
import { cancelLoading, setHeaderColor, setLoading } from 'store/common/actions';

export default function useCommon() {
  const { loading, headerColor } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const onLogin = useCallback((response: any) => dispatch(login({ response })), [dispatch]);
  const onSetHeaderColor = useCallback(
    ({ headerColor }: { headerColor: string }) => dispatch(setHeaderColor({ headerColor })),
    [dispatch]
  );

  const onCancelLoading = useCallback(() => dispatch(cancelLoading()), [dispatch]);
  const onSetLoading = useCallback(() => dispatch(setLoading()), [dispatch]);

  return {
    loading,
    headerColor,
    onLogin,
    onSetHeaderColor,
    onCancelLoading,
    onSetLoading,
  };
}
