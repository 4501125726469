import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

interface BrandStoryModalProps {
  open: boolean;
  onClose: () => void;
}

function BrandStoryModal({ open, onClose }: BrandStoryModalProps) {
  return (
    <Dialog className="brandstory" open={open} onClose={onClose}>
      <DialogContent>
        <button onClick={onClose}>
          <img className="notmobile" src="/img/close.svg" alt="close" />
        </button>
        <img src="/img/1.jpg" alt="brand_story"/>
        <img src="/img/2.jpg" alt="brand_story"/>
        <img src="/img/3.jpg" alt="brand_story"/>
        <img src="/img/4.gif" alt="brand_story"/>
        <img src="/img/5.jpg" alt="brand_story"/>
        <img src="/img/6.gif" alt="brand_story"/>
        <img src="/img/7.jpg" alt="brand_story"/>
      </DialogContent>
    </Dialog>
  );
}

export default BrandStoryModal;
