import BusinessHeader from 'components/business/BusinessHeader';
import BusinessSubMenu from 'components/business/BusinessSubMenu';
import BusinessTextImage2 from 'components/business/BusinessTextImage2';
import PortfolioModal from 'components/portfolio/PortfolioModal';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import { originalIPInfo } from 'lib/values/businessInfo';
import React, { useState } from 'react';

const menuList = [
  { target: 'content0', title: 'Happy Inside' },
  { target: 'content1', title: 'Hi Ice Cream' },
  { target: 'content2', title: 'Pink Duck' },
];
function OriginalIP() {
  const [focus, setFocus] = useState('content0');
  const { portfolioModal, onGetPortfolioDetail } = usePortfolio();

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    const portfolioId = e.currentTarget.getAttribute('data-portfolioid');

    if (portfolioId) {
      onGetPortfolioDetail({ _id: portfolioId });
    }
  };

  return (
    <div className="originalIpWrapper noscolling">
      <BusinessHeader
        target="originalIp"
        headerTitle={originalIPInfo.headerTitle}
        subTitle={originalIPInfo.subTitle}
        contents={originalIPInfo.contents}
      />

      <div className="contentSection">
        <div className="switchingPage">
          <BusinessSubMenu menuList={menuList} focus={focus} />
          {originalIPInfo.mainContents.map((data, idx) => (
            <BusinessTextImage2
              idx={idx}
              title={data.title}
              content={data.content}
              footer={data.footer}
              images={data.images}
              target="originalIP"
              setFocus={setFocus}
              onClick={onClick}
            />
          ))}
        </div>
      </div>

      {portfolioModal && <PortfolioModal />}
    </div>
  );
}

export default OriginalIP;
