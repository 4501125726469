import { GoogleLogin } from 'react-google-login';
import useUser from 'hooks/user/useUser';

function Login() {
  const { onLogin } = useUser();
  const clientId =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_CLIENT_ID_DEVELOPMENT
      : process.env.REACT_APP_CLIENT_ID_PRODUCTION;

  return (
    <div className="logIn">
      <GoogleLogin
        clientId={clientId || ''} // admin oauth 인증키.
        // buttonText="Login" //메일이 있으면? 로그아웃, 없으면? 로그인
        render={(renderProps) => <button onClick={renderProps.onClick}>Login</button>}
        accessType="online" //refresh token을 얻기위한 기본 값
        scope={'profile email openid'} //권한 요청 범위
        onSuccess={(response) => {
          onLogin({ response });
        }} // 로그인 성공시 이동할 함수
        onFailure={(res) => console.log(res)} //실패시 이동할 함수
        prompt="select_account" //항상 권한 요청을 하게 된다(사용 동의)-> consent | 사용자에게 계정선택을 준다 -> select_account | 없을경우(no 일 경우) 캐시에 남아있는걸로 자동 로그인한다.
      />
    </div>
  );
}

export default Login;
