import {
  Portfolio,
  LIST_PORTFOLIO_SUCCESS,
  OPEN_PORTFOLIO_MODAL,
  CLOSE_PORTFOLIO_MODAL,
  DETAIL_PORTFOLIO_SUCCESS,
  LIST_PORTFOLIO_REQUEST,
  LIST_BUSINESS_PORTFOLIO_SUCCESS,
  PORTFOLIO_RESET,
} from './types';
import { PortfolioActionTypes } from './actionTypes';

const initialState: Portfolio = {
  portfolioModal: false,
  portfolioMode: 'add',
  portfolioList: [],
  nowPage: 0,
  totalCount: 0,
  businessPortfolioList: [],
  maintag: '',
  subtag: [],
  name: '',
  portfolio: {
    _id: '',
    name: '',
    img: [],
    thumbnail: { title: '', src: '' },
    note: '',
    maintag: [],
    subtag: [],
    client: '',
    venue: '',
    orglist: [],
    BM: [],
    start: null,
    end: null,
    project: '',
    url: '',
  },
};

const portfolioReducer = (state = initialState, action: PortfolioActionTypes) => {
  switch (action.type) {
    case LIST_PORTFOLIO_REQUEST:
      return {
        ...state,
        maintag: action.maintag,
        subtag: action.subtag,
        name: action.name,
        portfolioMode: 'add',
      };
    case LIST_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolioModal: false,
        portfolioList:
          action.nowPage === 0
            ? action.portfolioList
            : state.portfolioList.concat(action.portfolioList),
        portfolio: initialState.portfolio,
        nowPage: action.nowPage,
        totalCount: action.totalCount,
      };
    case OPEN_PORTFOLIO_MODAL:
      return {
        ...state,
        portfolioModal: true,
        portfolioMode: action.mode,
      };
    case CLOSE_PORTFOLIO_MODAL:
      return {
        ...state,
        portfolioModal: false,
        portfolio: initialState.portfolio,
      };
    case DETAIL_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolioModal: true,
        portfolio: action.portfolio,
        portfolioMode: 'view',
      };
    case LIST_BUSINESS_PORTFOLIO_SUCCESS:
      return {
        ...state,
        businessPortfolioList: action.portfolio,
      };
    case PORTFOLIO_RESET:
      return {
        ...state,
        portfolio: initialState.portfolio,
        portfolioModal: false,
        nowPage: 0,
        totalCount: 0,
        portfolioList: [],
        portfolioMode: 'add',
      };
    default:
      return state;
  }
};

export default portfolioReducer;
