import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  CLOSE_USER_MODAL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  USER_FAILURE,
} from './types';
import { doLogin, doLogout, sendContact } from 'lib/api/userApi';
import { LoginUserRequestAction, SendContactAction } from './actionTypes';
import { setUserInfo, handleError, removeUserInfo } from 'store/common/sagas';
import { push } from 'lib/historyUtils';
import { SEND_CONTACT } from 'store/user/types';

function* login({ response }: LoginUserRequestAction) {
  try {
    const clientRes = yield call(doLogin, { response });
    const { code } = clientRes.data;

    if (code === 200) {
      yield put({
        type: LOGIN_USER_SUCCESS,
      });
      yield call(setUserInfo);
      yield push('/admin/portfolio');
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: USER_FAILURE,
    });
  }
}

function* logout() {
  try {
    const clientRes = yield call(doLogout);
    const { code } = clientRes.data;

    if (code === 200) {
      yield put({
        type: LOGOUT_USER_SUCCESS,
      });
      yield call(removeUserInfo);
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: USER_FAILURE,
    });
  }
}

function* sendEmail({ to, name, company, email, phone, text, file }: SendContactAction) {
  try {
    const clientRes = yield call(sendContact, { to, name, company, email, phone, text, file });
    const { status } = clientRes;

    if (status === 200) {
      alert('요청에 성공하였습니다.');
      yield put({
        type: CLOSE_USER_MODAL,
      });
    }
  } catch (e) {
    alert('요청에 실패하였습니다.');
    return;
  }
}
function* userRequest() {
  yield takeLatest(LOGIN_USER_REQUEST, login);
  yield takeLatest(LOGOUT_USER_REQUEST, logout);

  yield takeLatest(SEND_CONTACT, sendEmail);
}

export default function* userSaga() {
  yield all([userRequest()]);
}
