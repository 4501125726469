import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from 'store';
import { closeUserModal, login, openUserModal, sendEmail } from 'store/user/actions';

export default function useUser() {
  const { userModal } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const onLogin = useCallback((response: any) => dispatch(login({ response })), [dispatch]);
  const onSendEmail = useCallback(
    ({
      to,
      name,
      company,
      email,
      phone,
      text,
      file,
    }: {
      to: string;
      name: string;
      company: string;
      email: string;
      phone: string;
      text: string;
      file?: File;
    }) => dispatch(sendEmail({ to, name, company, email, phone, text, file })),
    [dispatch]
  );

  const onOpenUserModal = useCallback(() => dispatch(openUserModal()), [dispatch]);
  const onCloseUserModal = useCallback(() => dispatch(closeUserModal()), [dispatch]);

  return {
    userModal,
    onLogin,
    onSendEmail,
    onOpenUserModal,
    onCloseUserModal,
  };
}
