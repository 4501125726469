import Logo1 from 'css/img/clinet_logo/1.png';
import Logo2 from 'css/img/clinet_logo/2.png';
import Logo3 from 'css/img/clinet_logo/3.png';
import Logo4 from 'css/img/clinet_logo/4.png';
import Logo5 from 'css/img/clinet_logo/5.png';
import Logo6 from 'css/img/clinet_logo/6.png';
import Logo7 from 'css/img/clinet_logo/7.png';
import Logo8 from 'css/img/clinet_logo/8.png';
import Logo9 from 'css/img/clinet_logo/9.png';
import Logo10 from 'css/img/clinet_logo/10.png';
import Logo11 from 'css/img/clinet_logo/11.png';
import Logo12 from 'css/img/clinet_logo/12.png';
import Logo13 from 'css/img/clinet_logo/13.png';
import Logo14 from 'css/img/clinet_logo/14.png';
import Logo15 from 'css/img/clinet_logo/15.png';
import Logo16 from 'css/img/clinet_logo/16.png';
import Logo17 from 'css/img/clinet_logo/17.png';
import Logo18 from 'css/img/clinet_logo/18.png';
import Logo19 from 'css/img/clinet_logo/19.png';
import Logo20 from 'css/img/clinet_logo/20.png';
import Logo21 from 'css/img/clinet_logo/21.png';
import Logo22 from 'css/img/clinet_logo/22.png';
import Logo23 from 'css/img/clinet_logo/23.png';
import Logo24 from 'css/img/clinet_logo/24.png';
import Logo25 from 'css/img/clinet_logo/25.png';
import Logo26 from 'css/img/clinet_logo/26.png';
import Logo27 from 'css/img/clinet_logo/27.png';
import Logo28 from 'css/img/clinet_logo/28.png';
import Logo29 from 'css/img/clinet_logo/29.png';
import Logo30 from 'css/img/clinet_logo/30.png';
import Logo31 from 'css/img/clinet_logo/31.png';
import Logo32 from 'css/img/clinet_logo/32.png';
import Logo33 from 'css/img/clinet_logo/33.png';
import Logo34 from 'css/img/clinet_logo/34.png';
import Logo35 from 'css/img/clinet_logo/35.png';
import Logo36 from 'css/img/clinet_logo/36.png';
import Logo37 from 'css/img/clinet_logo/37.png';
import Logo38 from 'css/img/clinet_logo/38.png';
import Logo39 from 'css/img/clinet_logo/39.png';
import Logo40 from 'css/img/clinet_logo/40.png';
import Logo41 from 'css/img/clinet_logo/41.png';
import Logo42 from 'css/img/clinet_logo/42.png';
import Logo43 from 'css/img/clinet_logo/43.png';

export const logoImageList = [
  { front: Logo1, back: Logo25 },
  { front: Logo2, back: Logo26 },
  { front: Logo3, back: Logo27 },
  { front: Logo4, back: Logo28 },
  { front: Logo5, back: Logo29 },
  { front: Logo6, back: Logo30 },
  { front: Logo7, back: Logo31 },
  { front: Logo8, back: Logo32 },
  { front: Logo9, back: Logo33 },
  { front: Logo10, back: Logo34 },
  { front: Logo11, back: Logo35 },
  { front: Logo12, back: Logo36 },
  { front: Logo13, back: Logo37 },
  { front: Logo14, back: Logo38 },
  { front: Logo15, back: Logo39 },
  { front: Logo16, back: Logo40 },
  { front: Logo17, back: Logo41 },
  { front: Logo18, back: Logo42 },
  { front: Logo19, back: Logo43 },
  { front: Logo20, back: '' },
  { front: Logo21, back: '' },
  { front: Logo22, back: '' },
  { front: Logo23, back: '' },
  { front: Logo24, back: '' },
];
