export function setDateFormat(value: string, format: string) {
  const date = new Date(value);

  const dateVal = format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function ($1) {
    if ($1) {
      switch ($1) {
        case 'yyyy':
          return date.getFullYear().toString();
        case 'yy':
          return zeroFill(date.getFullYear() % 1000);
        case 'MM':
          return zeroFill(date.getMonth() + 1);
        case 'dd':
          return zeroFill(date.getDate());
        case 'HH':
          return zeroFill(date.getHours());
        case 'hh':
          return zeroFill(date.getHours() % 12 ? date.getHours() % 12 : 12);
        case 'mm':
          return zeroFill(date.getMinutes());
        case 'ss':
          return zeroFill(date.getSeconds());
        default:
          return $1;
      }
    }

    return '';
  });

  return dateVal;
}

function zeroFill(value: number) {
  if (value >= 10) {
    return value + '';
  } else {
    return '0' + value;
  }
}
