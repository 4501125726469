import {
  CLOSE_USER_MODAL,
  LOGIN_USER_REQUEST,
  LOGOUT_USER_REQUEST,
  OPEN_USER_MODAL,
  SEND_CONTACT,
} from './types';

export const login = ({ response }: { response: any }) => ({
  type: LOGIN_USER_REQUEST,
  response,
});

export const logout = () => ({
  type: LOGOUT_USER_REQUEST,
});

export const sendEmail = ({
  to,
  name,
  company,
  email,
  phone,
  text,
  file,
}: {
  to: string;
  name: string;
  company: string;
  email: string;
  phone: string;
  text: string;
  file?: File;
}) => ({
  type: SEND_CONTACT,
  to,
  name,
  company,
  email,
  phone,
  text,
  file,
});

export const openUserModal = () => ({
  type: OPEN_USER_MODAL,
});

export const closeUserModal = () => ({
  type: CLOSE_USER_MODAL,
});
