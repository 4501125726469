import ContactModal from 'components/contact/ContactModal';
import useUser from 'hooks/user/useUser';

function Contact() {
  const { userModal, onOpenUserModal } = useUser();

  return (
    <>
      <div className="bgContact">
        <img src="/img/bg_contact.png" alt="bg contact" />
      </div>
      <div className="contactWrapper">
        <div className="infoWrapper">
          <div className="title">
            JOIN OUR<br/> IMAGINA<span>-</span>TION
          </div>
          <div className="mailAddress">
            webmaster@promotion.co.kr
          </div>
          <button className="contactUs" onClick={onOpenUserModal}>CONTACT US</button>
          <div className="locationWrapper">
            <div className="loctionAddress">
              <div className="title">Seoul</div>
              <div className="tel">(+82)2-3498-0800</div>
              <div className="address">
                <span>
                  FM Building, 34, Banpo-daero 22-gil,
                  <br /> Seocho-gu, Seoul, 06649, Korea
                </span>
                <span>
                  서울특별시 서초구 반포대로 22길 34
                  <br /> 에프엠 빌딩 06649
                </span>
              </div>
            </div>
            <div className="loctionAddress">
              <div className="title">Los Angeles</div>
              <div className="tel">(+1)213-369-1224</div>
              <div className="address">
                <span>
                  555 W 5th st, Los Angeles, CA <br /> 90013
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userModal && <ContactModal />}
    </>
  );
}
export default Contact;
