import usePortfolio from 'hooks/portfolio/usePortfolio';
import qs from 'qs';
import React, { useEffect } from 'react';
import PortfolioTile from './PortfolioTile';
import { useLocation } from 'react-router-dom';

function PortfolioList() {
  const {
    portfolioList,
    onGetPortfolioList,
    nowPage,
    totalCount,
    maintag,
    subtag,
  } = usePortfolio();
  const location = useLocation();

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { tag1, tag2 } = query;

  const getTagName = (mainTag: string) => {
    switch (mainTag) {
      case 'hybridevent':
        return 'Hybrid Event';
      case 'onlineLiveSystem':
        return 'Online Live System';
      case 'virtualStudio':
        return 'Virtual Studio';
      case 'originalIp':
        return 'Original IP';
      case 'design':
        return 'Design';
      case 'itSolution':
        return 'IT Solution';
      default:
        return 'Hybrid Event';
    }
  };

  useEffect(() => {
    if (tag1) {
      const mainTag = tag1.toString();
      const subTag = tag2 ? tag2.toString() : '';

      const tag = getTagName(mainTag);
      onGetPortfolioList({
        maintag: tag,
        subtag: subTag ? [subTag] : [],
        name: '',
        nowPage: 0,
      });
    } else {
      onGetPortfolioList({ maintag: '', subtag: [], name: '', nowPage: 0 });
    }
  }, []);

  const onClick = () => {
    onGetPortfolioList({ maintag, subtag, name: '', nowPage: nowPage + 1 });
  };

  return (
    <div className="portFolioWrapper">
      {portfolioList &&
        portfolioList.map((portfolio) => (
          <PortfolioTile portfolio={portfolio} key={portfolio._id} />
        ))}
      {12 * (nowPage + 1) < totalCount && totalCount > 12 && (
        <button className="seeMore" onClick={onClick}>
          SEE MORE
        </button>
      )}
    </div>
  );
}

export default PortfolioList;
