export interface User {
  userModal: boolean;
}
/** User 관련 action 상수 정의 */
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const USER_FAILURE = 'USER_FAILURE';

export const SEND_CONTACT = 'SEND_CONTACT';
export const OPEN_USER_MODAL = 'OPEN_USER_MODAL';
export const CLOSE_USER_MODAL = 'CLOSE_USER_MODAL';
