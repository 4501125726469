import Paging from 'components/common/Paging';
import PopupModal from 'components/popup/PopupModal';
import usePopup from 'hooks/popup/usePopup';
import { setDateFormat } from 'lib/setDateFormat';
import React, { useEffect, useState } from 'react';
import { PopupInfo } from 'store/popup/types';

function AdminPopup() {
  const storageUser = localStorage.getItem('portfolio');
  const {
    popupList,
    nowPage,
    totalCount,
    popupModal,
    onGetPopupList,
    onOpenPopupModal,
    onClosePopupModal,
  } = usePopup();
  const [mode, setMode] = useState('add');
  const [popup, setPopup] = useState<PopupInfo>();

  useEffect(() => {
    onGetPopupList({ nowPage: 0, target: 'admin' });
  }, []);

  const addPopup = () => {
    setPopup(undefined);
    setMode('add');
    onOpenPopupModal();
  };

  const movePage = (value: number) => {
    onGetPopupList({ nowPage: value, target: 'admin' });
  };

  const editPopup = (e: React.MouseEvent<HTMLElement>) => {
    const targetId = e.currentTarget.getAttribute('data-id');

    if (targetId) {
      const selPopup = popupList.filter((data) => data._id === targetId)[0];
      if (selPopup) {
        setPopup(selPopup);
        setMode('edit');
        onOpenPopupModal();
      }
    }
  };

  const onClosePopup = () => {
    setPopup(undefined);
    setMode('add');
    onClosePopupModal();
  };

  return (
    <div className="popupSettingWrapper">
      {storageUser === 'yes' && <button className="addPopup" onClick={addPopup}>
        <img src="/img/add.svg" alt="add popup" />
      </button>}
      <div className="popupListWrapper">
        {popupList &&
          popupList.map((data) => (
            <div key={data._id} className="singlePopup">
              <img src={data.img} alt="popup" />
              <div className="info"><label>url</label><span>{data.url}</span></div>
              <div className="info"><label>종료일</label><span>{data.expire ? setDateFormat(data.expire.toString(), 'yyyy-MM-dd') : '-'}</span>
              </div>
              <button onClick={editPopup} data-id={data._id}>
                수정
              </button>
            </div>
          ))}
      </div>
      <Paging totalCount={totalCount} nowPage={nowPage} countPerPage={10} setPage={movePage} />
      {popupModal && (
        <PopupModal open={popupModal} onClose={onClosePopup} popup={popup} mode={mode} />
        )}
    </div>
  );
}

export default AdminPopup;
