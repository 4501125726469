import { Dialog, DialogContent } from '@material-ui/core';
import DatePicker from 'components/common/DatePicker';
import Loading from 'components/common/Loading';
import useCommon from 'hooks/common/useCommon';
import usePopup from 'hooks/popup/usePopup';
import { setDateFormat } from 'lib/setDateFormat';
import useInput from 'lib/useInput';
import React, { useState } from 'react';
import { PopupInfo } from 'store/popup/types';

interface PopupModalProps {
  open: boolean;
  mode: string;
  popup?: PopupInfo;
  onClose: () => void;
}

function PopupModal({ open, mode, popup, onClose }: PopupModalProps) {
  const { loading } = useCommon();
  const { onInsertPopup, onDeletePopup, onUpdatePopup } = usePopup();
  const [url, setUrl] = useInput(popup?.url || '');
  const [addImg, setAddImg] = useState<File>();
  const [expire, setExpire] = useState(popup?.expire || null);
  const [dateModal, setDateModal] = useState(false);

  const resetEndDate = () => {
    setExpire(null);
  };

  const onSubmit = () => {
    if (mode === 'add' && !addImg) {
      alert('이미지를 선택해주세요.');
      return;
    }

    mode === 'add' && addImg && onInsertPopup({ url, addImg, expire });
    popup &&
      mode === 'edit' &&
      onUpdatePopup({ _id: popup._id, url, addImg, expire, img: popup.img });
  };

  const onDelete = () => {
    const response = window.confirm('정말로 삭제하시겠습니까?');

    if (response) {
      popup && onDeletePopup({ _id: popup._id });
    }
  };

  const handleDateChange = ({ id, date }: { id: string; date: Date | null }) => {
    setExpire(date);
    setDateModal(false);
  };

  const handleDateModal = () => {
    setDateModal(!dateModal);
  };

  const onAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : null;

    if (files && files[0]) {
      setAddImg(files[0]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick disableEscapeKeyDown>
      {loading && <Loading />}
      <DialogContent className="popupSettingModalWrapper" >
        <div className="inputWrapper">
          <label>url</label>
          <input type="text" value={url} onChange={setUrl} />
        </div>
        <div className="inputWrapper">
          <label>종료일</label> 
            <div className="endDate">
              {expire ? setDateFormat(expire.toString(), 'yyyy-MM-dd') : ' '}
            </div>
            <button onClick={resetEndDate} className="reset">초기화</button>
            <button onClick={handleDateModal} className="endDate">종료일</button>
        </div>
        <div className="inputWrapper">
          <label>썸네일</label>
          <input
          className="hiddenImgSelect"
            accept="image/*"
            name="image"
            id="contained-button-file"
            type="file"
            onChange={onAddImage}
          />
          <label htmlFor="contained-button-file" className="addImageBtn">
            Upload
          </label>
        </div>
        {!addImg && mode == 'add' && popup && <img src={popup.url} alt="popup" />}
        {addImg && <img src={URL.createObjectURL(addImg)} alt="popup" />}
        <button className="regist" onClick={onSubmit}>{mode === 'add' ? '등록' : '수정'}</button>
        {mode === 'edit' && <button className="delete" onClick={onDelete}>삭제</button>}
      </DialogContent>
      {dateModal && (
        <DatePicker
          value={expire}
          onChange={handleDateChange}
          id="set_enddate"
          open={dateModal}
          onClose={handleDateModal}
        />
      )}
    </Dialog>
  );
}

export default PopupModal;
