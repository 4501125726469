import { portfolioMainTag, portfolioSubTag } from 'lib/values/portfolioValues';
import styled from 'styled-components';
import React from 'react';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import PortfolioSearch from 'components/portfolio/PortfolioSearch';

interface PortfolioFilterProps {
  onHandleSubMenu: () => void;
}
function PortfolioFilter({ onHandleSubMenu }: PortfolioFilterProps) {
  const storageUser = localStorage.getItem('portfolio');

  const { maintag, subtag, onGetPortfolioList } = usePortfolio();

  const onChangeSubtag = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;

    if (subtag.includes(id)) {
      onGetPortfolioList({
        maintag,
        subtag: [id],
        name: '',
        nowPage: 0,
      });
    } else {
      onGetPortfolioList({ maintag, subtag: [id], name: '', nowPage: 0 });
    }
  };

  return (
    <div className="portFolioFilterWrapper">
      <div className="mainFilter">
        <label htmlFor="flterValue">Filter</label>
        <div id="filterValue" onClick={onHandleSubMenu}>
          {maintag ? maintag : 'All Projects'}
          <span>
            <img src="/img/down_arrow.svg" alt="down arrow" />
          </span>
        </div>
      </div>
      {maintag === 'Hybrid Event' && (
        <div className="hybridEventValue">
          {portfolioSubTag.map((data) => (
            <Tag selected={subtag.includes(data)} onClick={onChangeSubtag} id={data}>
              {data}
            </Tag>
          ))}
        </div>
      )}
      {storageUser === 'yes' && maintag === '' && <PortfolioSearch />}
    </div>
  );
}

interface TagProps {
  selected: boolean;
}

const Tag = styled.span<TagProps>`
  color: ${({ selected }) => (selected ? `#9755FF` : `#000`)};
`;

export default PortfolioFilter;
