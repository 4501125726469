import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {
  DetailPortfolioRequestAction,
  InsertPortfolioRequestAction,
  ListPortfolioRequestAction,
  UpdatePortfolioRequestAction,
  DeletePortfolioRequestAction,
  ListBusinessPortfolioRequestAction,
} from './actionTypes';
import {
  deletePortfolio,
  detailPortfolio,
  getBusinessPorfolioList,
  insertPortfolio,
  listPortfolio,
  updatePortfolio,
} from 'lib/api/portfolioApi';
import {
  DELETE_PORTFOLIO_REQUEST,
  DETAIL_PORTFOLIO_REQUEST,
  DETAIL_PORTFOLIO_SUCCESS,
  INSERT_PORTFOLIO_REQUEST,
  LIST_BUSINESS_PORTFOLIO_REQUEST,
  LIST_BUSINESS_PORTFOLIO_SUCCESS,
  LIST_PORTFOLIO_REQUEST,
  LIST_PORTFOLIO_SUCCESS,
  PORTFOLIO_FAILURE,
  UPDATE_PORTFOLIO_REQUEST,
} from './types';
import { handleError } from 'store/common/sagas';
import { RootState } from 'store';

export const getPortfolio = (state: RootState) => state.portfolio;

export function* list({ maintag, subtag, name, nowPage }: ListPortfolioRequestAction) {
  try {
    const clientRes = yield call(listPortfolio, { maintag, subtag, name, nowPage });
    const { status, data } = clientRes;

    if (status === 200) {
      yield put({
        type: LIST_PORTFOLIO_SUCCESS,
        portfolioList: data.list,
        nowPage,
        totalCount: data.totalCount,
      });
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: PORTFOLIO_FAILURE,
    });
  }
}

export function* detail({ _id }: DetailPortfolioRequestAction) {
  try {
    const clientRes = yield call(detailPortfolio, { _id });
    const { status, data } = clientRes;

    if (status === 200) {
      yield put({
        type: DETAIL_PORTFOLIO_SUCCESS,
        portfolio: data,
      });
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: PORTFOLIO_FAILURE,
    });
  }
}

export function* insert({ portfolio, addImg, thumbnail }: InsertPortfolioRequestAction) {
  try {
    const clientRes = yield call(insertPortfolio, { portfolio, addImg, thumbnail });
    const { status } = clientRes;
    const state = yield select(getPortfolio);

    if (status === 200) {
      yield call(list, {
        maintag: state.maintag,
        subtag: state.subtag,
        nowPage: 0,
      } as ListPortfolioRequestAction);
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: PORTFOLIO_FAILURE,
    });
  }
}

export function* update({ portfolio, addImg, thumbnail }: UpdatePortfolioRequestAction) {
  try {
    const clientRes = yield call(updatePortfolio, { portfolio, addImg, thumbnail });
    const { status } = clientRes;
    const state = yield select(getPortfolio);

    if (status === 200) {
      yield call(list, {
        maintag: state.maintag,
        subtag: state.subtag,
        nowPage: 0,
      } as ListPortfolioRequestAction);
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: PORTFOLIO_FAILURE,
    });
  }
}

export function* deleteAction({ _id }: DeletePortfolioRequestAction) {
  try {
    const clientRes = yield call(deletePortfolio, { _id });
    const { status } = clientRes;
    const state = yield select(getPortfolio);

    if (status === 200) {
      yield call(list, {
        maintag: state.maintag,
        subtag: state.subtag,
        nowPage: 0,
      } as ListPortfolioRequestAction);
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: PORTFOLIO_FAILURE,
    });
  }
}

function* businessList({ maintag }: ListBusinessPortfolioRequestAction) {
  try {
    const clientRes = yield call(getBusinessPorfolioList, { maintag });
    const { status, data } = clientRes;

    if (status === 200) {
      yield put({
        type: LIST_BUSINESS_PORTFOLIO_SUCCESS,
        portfolio: data,
      });
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: PORTFOLIO_FAILURE,
    });
  }
}

function* setPortfolioRequest() {
  yield takeLatest(LIST_PORTFOLIO_REQUEST, list);
  yield takeLatest(DETAIL_PORTFOLIO_REQUEST, detail);
  yield takeLatest(INSERT_PORTFOLIO_REQUEST, insert);
  yield takeLatest(UPDATE_PORTFOLIO_REQUEST, update);
  yield takeLatest(DELETE_PORTFOLIO_REQUEST, deleteAction);
  yield takeLatest(LIST_BUSINESS_PORTFOLIO_REQUEST, businessList);
}
export default function* portfolioSaga() {
  yield all([setPortfolioRequest()]);
}
