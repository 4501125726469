export interface Popup {
  totalCount: number;
  nowPage: number;
  popupList: Array<PopupInfo>;
  popupModal: boolean;
}

export interface PopupInfo {
  _id: string;
  img: string;
  url: string;
  expire: Date;
}

export const OPEN_POPUP_MODAL = 'OPEN_POPUP_MODAL';
export const CLOSE_POPUP_MODAL = 'CLOSE_POPUP_MODAL';

export const LIST_POPUP_REQUEST = 'LIST_POPUP_REQUEST';
export const LIST_POPUP_SUCCESS = 'LIST_POPUP_SUCCESS';

export const INSERT_POPUP_REQUEST = 'INSERT_POPUP_REQUEST';
export const UPDATE_POPUP_REQUEST = 'UPDATE_POPUP_REQUEST';
export const DELETE_POPUP_REQUEST = 'DELETE_POPUP_REQUEST';

export const POPUP_FAILURE = 'POPUP_FIALURE';
