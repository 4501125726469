export const itSolutionInfo = {
  headerTitle: ['IT', 'SOLUTION'],
  subTitle: [
    { content: '콘텐츠와 대중을 ', element: '', className: '' },
    { content: '연결하는 온라인 환경', element: '', className: '' },
  ],
  contents: [
    { content: '브랜드에 대한 이해와 연구를 바탕으로 ', element: '', className: '' },
    { content: '온라인에서의 사용자 경험을 최적화하는 ', element: '', className: '' },
    { content: 'IT 솔루션을 제공합니다.', element: '', className: '' },
  ],
  mainContents: [
    {
      type: 'text',
      first: [],
      second: {
        title: ['대중과 브랜드의 ', '커뮤니케이션에서 ', '온라인 환경 구축은 ', '필수입니다.'],
        subTitle: [
          '우리는 수많은 웹 사이트에 접속하여 ',
          '정보를 검색하고 모바일 어플리케이션을 통해 ',
          '브랜드를 접합니다.',
          '에프엠커뮤니케이션즈의 IT 솔루션은 ',
          '온라인 환경에서 대중과 콘텐츠, 그리고 ',
          '브랜드를 연결합니다.',
        ],
        description: [],
      },
    },
    {
      type: 'textAndImage',
      first: ['/img/it_img_1.jpg'],
      second: {
        title: ['Web &', 'Responsive', 'Page'],
        subTitle: [],
        description: [
          '브랜드 아이덴티티를 적용시킨 개성 있는 ',
          'UI 디자인과 UX 설계로 콘텐츠에 가장 적합한 ',
          '웹 페이지와 반응형 페이지를 제작합니다. ',
          '각 기기에 최적화 된 UX 설계를 통해 PC, 모바일, ',
          '태블릿 PC 등 다양한 온라인 환경에서 대중과 ',
          '브랜드를 연결합니다.',
        ],
      },
    },
    {
      type: 'textAndImage',
      first: ['/img/it_img_2.jpg'],
      second: {
        title: ['Mobile', 'Application'],
        subTitle: [],
        description: [
          '사용자의 관점에서 출발하여 대중이 가장 쉽게 ',
          '브랜드를 만날 수 있는 모바일 어플리케이션을 ',
          '제작합니다. ',
          '기능의 편리성에 콘텐츠의 개성을 더한 다양한 ',
          '모바일 서비스를 바탕으로 브랜드의 이야기를 ',
          '효과적으로 전달합니다.',
        ],
      },
    },
  ],
};

export const designInfo = {
  headerTitle: ['DESIGN'],
  subTitle: [
    { content: 'Dare design.', element: '', className: '' },
    { content: '감히, 디자인 하다.', element: 'span', className: '' },
  ],
  contents: [
    { content: '“I am always doing that which I cannot do, ', element: '', className: '' },
    { content: 'in order that I may learn how to do it.”', element: '', className: '' },
    { content: 'Pablo Ruiz Picasso - ', element: 'span', className: 'small' },
    { content: '“나는 항상 내가 할 수 없는 것을 한다,', element: 'span', className: 'unique' },
    {
      content: '그렇게 하면 그것들을 할 수 있게 되기 때문이다.” ',
      element: 'span',
      className: 'unique',
    },
    { content: '파블로 루이즈 피카소 -', element: 'span', className: 'small' },
  ],
  mainContents: [
    {
      title: ['에프엠의 디자인'],
      content: [
        '"모든 창조는 파괴가 선행되어야 한다."는 ',
        '피카소의 말처럼 항상 새로운 것을 시도하고 ',
        '끊임없이 도전하는 디자인을 하고자 합니다.',
      ],
      footer: {
        type: 'text',
        content: [
          { content: 'Brand Design - 브랜드 디자인', href: '' },
          { content: 'UI/UX Design - 사용자 경험 디자인', href: '' },
          { content: 'Character/Illustration - 캐릭터/일러스트 디자인', href: '' },
          { content: 'Goods/Product Design - 굿즈/프로덕트 디자인', href: '' },
          { content: 'Online Contents Design - 온라인컨텐츠 디자인', href: '' },
        ],
      },
      images: [
        { url: 'branding', portfolioId: '' },
        { url: 'ui_ux', portfolioId: '' },
        { url: 'character', portfolioId: '' },
        { url: 'goods', portfolioId: '' },
        { url: 'onlineContents', portfolioId: '' },
      ],
    },
    {
      title: ['브랜드 디자인'],
      content: [
        '브랜드 아이덴티티를 개발하고, ',
        '고유의 특징을 살려 유저와 소통하는데 ',
        '필요한 이미지를 구축합니다.',
      ],
      footer: {
        type: 'text',
        content: [
          { content: 'Symbol / Logo Design - 심볼/로고 디자인 ', href: '' },
          { content: 'Pattern Design - 패턴 디자인', href: '' },
          { content: 'Infographics Design - 인포/아이콘 디자인', href: '' },
          { content: 'Typography - 타이포그라피', href: '' },
          { content: 'Application System - 각종 응용디자인 제작', href: '' },
        ],
      },
      images: [
        { url: 'logo', portfolioId: '' },
        { url: 'pattern', portfolioId: '' },
        { url: 'infographic', portfolioId: '' },
        { url: 'typo', portfolioId: '' },
        { url: 'app', portfolioId: '' },
      ],
    },
    {
      title: ['사용자 경험 디자인'],
      content: ['보다 편리한 인터페이스, ', '보다 유저 친화적인 경험을 ', '디자인 합니다.'],
      footer: {
        type: 'text',
        content: [
          { content: 'App Design - 앱 디자인', href: '' },
          { content: 'Web Design - 웹 디자인', href: '' },
        ],
      },
      images: [
        { url: 'appDesign', portfolioId: '' },
        { url: 'webDesign', portfolioId: '' },
      ],
    },
    {
      title: ['캐릭터 일러스트 디자인'],
      content: ['캐릭터와 일러스트는 브랜드와 컨텐츠에 ', '독창성과 생명력을 부여합니다.'],
      footer: {
        type: 'text',
        content: [
          { content: 'Character Design - 캐릭터 디자인', href: '' },
          { content: 'Concept Art - 인물과 배경 컨셉아트', href: '' },
          { content: 'Emoticon Design - 이모티콘 디자인', href: '' },
          { content: 'Animation - 애니메이션', href: '' },
        ],
      },
      images: [
        { url: 'emoticon', portfolioId: '' },
        { url: 'animation', portfolioId: '' },
        { url: 'character', portfolioId: '' },
        { url: 'conceptArt', portfolioId: '' },
      ],
    },
    {
      title: ['브랜드 굿즈와', '제품 디자인'],
      content: ['브랜드굿즈는 소비자를 사용자에서 ', '팬으로 만듭니다.'],
      footer: {
        type: 'text',
        content: [
          { content: 'Brand goods Design - 브랜드 굿즈 디자인', href: '' },
          { content: 'Product Concept - 제품 컨셉 디자인', href: '' },
          { content: 'Product Mockup - 제품 목업 디자인', href: '' },
          { content: 'Editorial Design - 출판 인쇄물 디자인', href: '' },
        ],
      },
      images: [
        { url: 'productConcept', portfolioId: '' },
        { url: 'productMockup', portfolioId: '' },
        { url: 'brandGoods', portfolioId: '' },
        { url: 'editorialDesign', portfolioId: '' },
      ],
    },
    {
      title: ['온라인 컨텐츠 디자인'],
      content: [
        '타겟을 먼저 분석하고 흥미를 끌만한 ',
        '컨텐츠들을 다양한 형태로 ',
        '만들어 냅니다',
      ],
      footer: {
        type: 'text',
        content: [
          { content: 'SNS Contents Design - SNS 콘텐츠 디자인', href: '' },
          { content: 'Motion Graphics - 모션그래픽 / 영상디자인', href: '' },
          { content: 'Marketing Contents - 마케팅 콘텐츠 디자인', href: '' },
        ],
      },
      images: [
        { url: 'snsContentsDesign', portfolioId: '' },
        { url: 'motionGraphics', portfolioId: '' },
        { url: 'marketingContents', portfolioId: '' },
      ],
    },
  ],
};

export const fmLabInfo = {
  headerTitle: ['FM LAB'],
  subTitle: [
    { content: '문화 · 기술 콘텐츠의', element: '', className: '' },
    { content: '미래를 연구하다.', element: '', className: '' },
  ],
  contents: [
    { content: '국가 공식 기관의 인증을 받은 ', element: '', className: '' },
    { content: '에프엠커뮤니케이션즈의 기업 연구소는 ', element: '', className: '' },
    // { content: '문화 콘텐츠와 기술 분야의 연구를 통해 ', element: '', className: '' },
    { content: '하이브리드 문화 기술 콘텐츠의 영역을 ', element: '', className: '' },
    { content: '더욱 넓혀가고 있습니다.', element: '', className: '' },
  ],
};

export const hybridInfo = {
  headerTitle: ['HYBRID', 'EVENT'],
  subTitle: [
    { content: '브랜드를 가장 잘', element: '', className: '' },
    { content: '느낄 수 있는 방법', element: '', className: '' },
  ],
  contents: [
    // { content: '창의적인 상상력과 노하우로 브랜드 경험을 ', element: '', className: '' },
    // { content: '극대화하는 이벤트 콘텐츠를 제작합니다.', element: '', className: '' },
    // { content: '', element: '', className: '' },
    { content: '온 · 오프라인의 경계를 허물어 ', element: '', className: '' },
    { content: '브랜드 경험을 극대화 하는 ', element: '', className: '' },
    { content: '이벤트를 만듭니다.', element: '', className: '' },
  ],
  mainContents: [
    {
      title: ['온라인으로의', '브랜드 경험 확장'],
      subtitle1: [
        '이벤트 페이지 기획 및 운영, 플랫폼, 영상, ',
        'SNS 홍보 기획 및 집행, 광고 캠페인까지 ',
        '브랜드 경험을 더욱 확장하는 ',
        '디지털 마케팅 서비스를 제공합니다.',
      ],
      // subtitle2: [
      //   '이벤트 페이지 기획 및 운영, 플랫폼, 영상, ',
      //   'SNS 홍보 기획 및 집행, 광고 캠페인까지 ',
      //   '브랜드 경험을 더욱 확장하는 ',
      //   '디지털 마케팅 서비스를 제공합니다.',
      // ],
      description: [
        '2020 포카리 스웨트 블루라벨 캠페인',
        '2020 GENESIS G80 Digital Showcase',
        '2020 MIR 4 ONTACT : SHOWCASE',
        '2020 트위치 X KBO 온라인 캠페인',
      ],
      target: 'Digital',
    },
    {
      title: ['브랜드 체험의', '극대화'],
      subtitle1: [
        '제품을 검색하게 되는 세일즈 프로모션 ',
        '체험하고 싶은 전시부스와 게임 이벤트 ',
        '기업 시상식과  콘서트를 아우르는 화려한 연출 행사 ',
        '대중과 브랜드가 만나는 접점에는 ',
        '항상 에프엠커뮤니케이션즈가 있습니다.',
      ],
      // subtitle2: [
      //   '상품을 검색하게 되는 세일즈 프로모션, ',
      //   '전시장에서 사진을 찍게 되는 전시부스, ',
      //   '체험하고 싶은 게임 이벤트 ',
      //   '기업 시상식과 콘서트를 아우르는 화려한 연출 행사 ',
      //   '그 속에는 항상 에프엠커뮤니케이션즈가 있습니다.',
      // ],
      description: [
        '2020 차세대 미디어 대전',
        "2020 BMW Owner's Track Day",
        '2020 Coupang Reveal ',
        '2014-2018 G-STAR NEXON',
        '2020 BMW COVID-19 바이러스 제어 서비스',
        '2019 신한 은행 종합 업적 평가 대회',
        // '2017-2019 G-STAR  카카오 게임즈',
        // '2018 평창 동계올림픽 성화 봉송(삼성 전자)',
        // '2019 전북은행 창립 50주년 기념식',
      ],
      target: 'Promotion',
    },
    {
      title: ['압도적인 스케일,', '안정적인 운영'],
      subtitle1: [
        '지자체 행사부터 대규모 국가 행사까지 ',
        '탄탄한 인프라와 기획력을 기반으로 ',
        '국·내외 다양한 복합 프로젝트를 수행하며 ',
        '기획에서 회장 운영, 의전 및 홍보까지 ',
        '종합적인 서비스를 제공합니다.',
      ],
      // subtitle2: [
      //   '업계 최고 규모의 전문가들을 바탕으로 기획, ',
      //   '회장 운영 및 홍보까지 안정적인 서비스를 ',
      //   '제공합니다.',
      // ],
      description: [
        // '2018 G-20 2018 평탕을 준비하는 사람들',
        '2020 제 72회 국군의 날 행사',
        '2020 한·아세안 문화 협력 포럼',
        '2020 대한 체육회 100주년 기념 사업',
        '2018 평창 동계 올림픽 개·폐회식 연출 및 운영',
        "2017 평화 올림픽을 위한 메트로폴리탄 '평창의 밤'",
      ],
      target: 'International',
    },
    {
      title: ['대중과 호흡하는', '브랜드 페스티벌'],
      subtitle1: [
        '다채로운 공연과 연출이 이루어지는 메인 무대, ',
        '지루할 틈이 이벤트와 굿즈까지 ',
        '우리는 보고 듣는 것을 넘어 ',
        '브랜드와 대중이 함께 호흡하는 ',
        '문화 페스티벌을 만듭니다.',
      ],
      // subtitle2: [
      //   '다채로운 공연과 연출이 이루어지는 메인 무대, ',
      //   '지루할 틈이 없는 미니 이벤트, 소중한 경험을 ',
      //   '추억으로 간직하는 이벤트 굿즈까지 ',
      //   '우리는 브랜드 스토리가 가득한 페스티벌을 ',
      //   '만들어갑니다.',
      // ],
      description: [
        "2020 Let's DMZ",
        '2018-2019 한화와 함께하는 서울 세계 불꽃 축제',
        '2017-2019 포스코 콘서트',
        '2018 현대카드 슈퍼콘서트 23 샘 스미스',
        '2018 비타코코 썸머 비치 페스티벌',
        // '2017 KIA SURPRISED WEEKEND',
        // '2018 닛산 익사이팅 콘서트',
      ],
      target: 'Festival',
    },
    {
      title: ['브랜드를 보여주는', '최적의 공간'],
      subtitle1: [
        '브랜드와 대중이 만나는 특별한 공간을 기획하며 ',
        '팝업 스토어, 초대형 복합 시설의 연간 대행 등의 ',
        '안정적인 운영을 통해 브랜드 스토리를 ',
        '대중에게 전합니다.',
      ],
      // subtitle2: [
      //   '공간기획 부터 디자인, 팝업 스토어, ',
      //   '초대형 복합 시설의 연간 대행 등 안정적인 운영을 ',
      //   '통해 브랜드 스토리를 대중에게 전합니다.',
      // ],
      description: [
        '2020 AK Family Park with 텔레토비',
        '2020 NEXON 핑크빈 하우스',
        '2018 COEX Winter Seoul Festival',
        '2016-2019 커먼그라운드 공간 마케팅',
        '2009-2018 타임스퀘어 문화 이벤트',
        // '2018 Master Card Nexus',
      ],
      target: 'Space',
    },
  ],
};

export const onlineLiveInfo = {
  headerTitle: ['B.BLOCK'],
  subTitle: [
    { content: 'Borderless + Block ', element: '', className: '' },
    { content: '이벤트의 경계를 허물다', element: '', className: '' },
  ],
  contents: [
    { content: '오프라인 이벤트의 감동을 온라인을 ', element: '', className: '' },
    { content: '통해 실시간으로 전합니다.', element: '', className: '' },
  ],
  mainContents: [
    {
      type: 'textAndImage',
      first: ['/img/bb_img_1.jpg'],
      second: {
        title: [],
        subTitle: [],
        description: [
          'B.BLOCK은 비대면 시대에 ',
          '전 세계인이 함께하는 ',
          '소통의 장을 만드는 ',
          '온라인 라이브 시스템 입니다.',
          '',
          '컨퍼런스, 포럼 등의 회의부터 ',
          '기념식, 시상식, 신제품 발표회, ',
          '공연/콘서트까지 다양한 형태의 ',
          '라이브 이벤트를 만드는 ',
          'B.BLOCK의 차별화 된 ',
          '시스템을 소개합니다.',
        ],
      },
      image: 'hello',
    },
    {
      type: 'textAndImage',
      first: ['/img/bb_img_2.jpg'],
      second: {
        title: ['Accessibility'],
        subTitle: ['기존 화상 회의 플렛폼 ', '호환 및 최적화'],
        description: [
          '온라인 라이브 이벤트 진행을 위해 별도 플랫폼 ',
          '설치 및 교육 없이 사용하던 것 그대로, ',
          '쉽게 즐길 수 있습니다. ',
          'B.BLOCK은 별도의 지정 프로그램없이 정부 ',
          '부처, 기업 별 평소 사용하는 ',
          '화상회의 플랫폼을 활용합니다. ',
          '화상 회의 및 스트리밍 플랫폼을 최적화하여 ',
          '고품질의 이벤트 영상과 음향을 제공합니다.',
        ],
      },
    },
    {
      type: 'textAndImage',
      first: ['/img/bb_img_3.jpg'],
      second: {
        title: ['Communication'],
        subTitle: ['1,000명 이상 참가자 화면 노출'],
        description: [
          '지속적인 테스트 및 이벤트 진행을 통해 서버 ',
          '안정성을 확인하였으며 이벤트 중 1,000명 ',
          '이상의 다수의 참가자 화상 화면을 다양하게 노출 ',
          '시킬 수 있는 고성능 네트워크 서버 시스템을 ',
          '탑재하였습니다. ',
          '더욱 많은 사람들과 함께 라이브 이벤트를 ',
          '즐겨보세요.',
        ],
      },
    },
    {
      type: 'textAndImage',
      first: ['/img/bb_img_4.jpg'],
      second: {
        title: ['Sound'],
        subTitle: ['고품질의 오디오 송출'],
        description: [
          '오프라인 콘서트, 공연의 현장감을 그대로 ',
          '',
          'B.BLOCK은 자체 장비와 전문 인력을 바탕으로 ',
          '기존 화상 회의 플랫폼의 오디오 그 이상의 ',
          '고품질 사운드를 제공합니다. ',
        ],
      },
    },
    {
      type: 'textAndImage',
      first: ['/img/bb_img_5.jpg'],
      second: {
        title: ['Visual'],
        subTitle: ['다양한 화면 연출'],
        description: [
          '영상의 퀄리티를 상승 시키는 연출 효과 ',
          '이벤트 프로그램에 따른 맞춤 화면 전환 ',
          '',
          '이벤트 현장과 온라인 시스템의 이원화를 ',
          '바탕으로 중계, 사전 제작 영상, 문서 자료 화면 등 ',
          '자유로운 화면 구성 및 연출이 가능합니다. ',
          '',
          'B.BLOCK에서만 가능한, 그래서 더욱 색다른 ',
          '연출 효과를 경험해보세요.',
        ],
      },
    },
  ],
};

export const originalIPInfo = {
  headerTitle: ['HI', 'ISLAND'],
  subTitle: [
    { content: '익숙한 것을 보다 ', element: '', className: '' },
    { content: '색다르게', element: '', className: '' },
  ],
  contents: [
    {
      content: '일상 소재를 높은 상상력으로 표현한 ',
      element: '',
      className: 'small',
    },
    { content: 'IP 콘텐츠를 통해 대중에게 새로운 ', element: '', className: 'small' },
    { content: '문화 경험을 선사합니다. ', element: '', className: 'small' },
    // { content: '', element: '', className: 'small' },
    // {
    //   content: '전시, 출판, 브랜드 굿즈, 콜라보레이션 등 다방면의 ',
    //   element: '',
    //   className: 'small',
    // },
    // { content: '분야에서 문화 콘텐츠 IP 사업을 통한 브랜드 ', element: '', className: 'small' },
    // { content: '커뮤니케이션을 진행합니다.', element: '', className: 'small' },
  ],
  mainContents: [
    {
      title: ['일상을', '사랑스럽게'],
      content: [
        'Always be Happy',
        'HAPPY INSIDE는 이모티콘을 오브제로 하여 ',
        '행복이라는 주제를 다양한 문화 콘텐츠로 표현하는 브랜드입니다. ',
        '전시를 시작으로 메인 캐릭터인 보블리를 크리에이터화하여 ',
        '메타버스 속 일상을 사랑스럽게 만들어 가고 있습니다. ',
        '보블리와 함께 메타버스 세상을 즐겨보세요. ',
      ],
      footer: {
        type: 'link',
        content: [
          {
            content: 'Youtube',
            href: 'https://www.youtube.com/channel/UCHSfX4trG0-_Q3KR1DJNAJA/featured',
          },
          { content: 'Website', href: 'http://happyinside.co.kr/' },
          { content: 'Instagram', href: 'https://www.instagram.com/hi_happyinside/' },
        ],
      },
      images: [
        { url: 'exhibition', portfolioId: '5fdc24d40c2b9758ff0d09d7' },
        { url: 'fivexo', portfolioId: '5fdc215c0c2b9758ff0d09c9' },
        { url: 'vovelyTvLogo', portfolioId: '' },
        { url: 'pinkCastle', portfolioId: '' },
      ],
    },
    {
      title: ['아이스크림에', '빠지다!'],
      content: [
        'We love Ice Cream',
        '친숙한 소재인 아이스크림에 핑크 컬러를 더해 ',
        '아이스크림을 한 입 먹었을 때의 행복, 그 달콤한 ',
        '감정을 감각적으로 표현한 브랜드 입니다.',
        // '브랜드 아이덴티티를 기반으로 전시와 타 브랜드와의 ',
        // '콜라보레이션을 통해 브랜드 영역을 확장해 가고 ',
        // '있습니다.',
      ],
      footer: {
        type: 'link',
        content: [{ content: 'Website', href: 'http://hi-icecream.com/' }],
      },
      images: [
        { url: 'exhibition2', portfolioId: '5fdc327b0c2b9758ff0d09dd' },
        { url: 'brandGoods', portfolioId: '5fdc4c3d0c2b9758ff0d09ec' },
      ],
    },
    {
      title: ['핑크를', '좋아하는 덕후'],
      content: [
        'PINK is My life',
        '핑덕은 세상을 둥글게 살고 싶어 하며 ',
        '좋아하는 것을 보면 발라당 누워버리는 ',
        '핑크 오리의 이야기를 담은 브랜드 입니다.',
        // '브랜드입니다. 2018년 오픈을 시작으로 ',
        // '디자인 개발을 통한 굿즈 및 영상 콘텐츠를 ',
        // '기획하고 있습니다.',
      ],
      footer: {
        type: 'link',
        content: [{ content: 'Instagram', href: 'https://www.instagram.com/hi_pinkduck/' }],
      },
      images: [
        { url: 'story', portfolioId: '5fdc50710c2b9758ff0d09f2' },
        { url: 'story2', portfolioId: '' },
        { url: 'brandedContents', portfolioId: '' },
      ],
    },
  ],
};

export const virtualInfo = {
  headerTitle: ['VORDERLESS', 'STUDIO'],
  subTitle: [{ content: '현실, 그 이상의 경험', element: '', className: '' }],
  contents: [
    { content: '상상의 한계를 뛰어넘는 실감 콘텐츠 (VR/AR) 구현을 ', element: '', className: '' },
    {
      content: '통해 가상과 현실의 경계를 넘나드는 독창적이고 놀라운 ',
      element: '',
      className: '',
    },
    { content: '경험을 제공합니다.', element: '', className: '' },
  ],
};

export const imagineLabInfo = {
  title: ['Metaverse', 'Contents Lab'],
  content: [
    '트렌드 분석과 창의적인 상상을 바탕으로 ',
    '미래 세대와 함께하는 메타버스 콘텐츠를 연구합니다.',
  ],
  functionList: [
    {
      image: '/img/hi_imagine_lab1.svg',
      title: '트렌드 분석',
      detail: ['메타버스 트렌드 및', '미래 소비 계층 연구'],
    },
    {
      image: '/img/common_lab.svg',
      title: 'IP 콘텐츠 기획',
      detail: ['메타버스 콘텐츠 IP 및', '스토리텔링 기획'],
    },
    {
      image: '/img/hi_imagine_lab2.svg',
      title: '콘텐츠 디자인/개발',
      detail: ['플랫폼에 최적화 된', '메타버스 콘텐츠 제작'],
    },
  ],
  linkList: [
    {
      title: `IP Contents`,
      detail: ['이모티콘과 함께하는', '행복한 상상', '<해피인사이드>'],
    },
    {
      title: 'Media Contents',
      detail: ['메타버스 크리에이터', '보블리의 메타버스 정복기', '<Youtube-VOVELY TV>'],
    },
    {
      title: 'Metaverse Contents',
      detail: ['메타버스 플랫폼 속', '드림 어드벤처', '<Zepeto/Roblox/Minecraft>'],
    },
  ],
};

export const techLabInfo = {
  title: ['4XTL.', 'TECH Lab'],
  content: [
    '4차 산업의 기술력과 아이디어를 결합하여 ',
    '미래 산업을 이끄는 서비스를 제시하고 ',
    '창의적인 실감형 콘텐츠를 개발합니다.',
  ],
  functionList: [
    {
      image: '/img/common_lab.svg',
      title: '디지털 서비스 기획',
      detail: ['온·오프라인 융·복합 ', '서비스 및 운영 솔루션 기획'],
    },
    {
      image: '/img/untact_tech_lab1.svg',
      title: '웹 개발',
      detail: ['웹 페이지 개발 및 UX 연구 ', '데이터 가공 및 관리를 위한 ', '웹 기술 솔루션 개발'],
    },
    {
      image: '/img/untact_tech_lab2.svg',
      title: '실감형 콘텐츠 엔진',
      detail: ['Unity 3D / Unreal / Real-time ', '엔진을 활용한 실감형 콘텐츠 제작 '],
    },
  ],
  linkList: [
    { title: 'Digital Solution', detail: ['Web / Mobile site', 'Mobile Application'] },
    { title: 'Media Solution', detail: ['Live Event Broadcasting', 'Media Contents Production'] },
    {
      title: 'Immersive Solution',
      detail: ['3D / Real-time Contents', 'CG / VFX', 'Virtual Studio', 'Real-time Rendering'],
    },
  ],
};

export const onlineLiveSystemDiagram = {
  title: ['ONE-STOP'],
  subtitle: ['라이브 이벤트의 모든 ', '과정을 한 번에'],
  description: [
    'B.BLOCK은 이벤트의 전체 기획부터 송출 및 ',
    '연출, 사후 관리까지 라이브 이벤트를 만드는 ',
    '모든 것을 한 번에 해결하는 ',
    'ONE-STOP LIVE Solution을 제공합니다.',
  ],
  diagram: [
    {
      number: '',
      title: '이벤트 총괄 기획',
      content: ['온·오프라인 이벤트 기획 및 콘텐츠 개발 ', '무대 연출 및 공간 구성 운영 계획'],
    },
    {
      number: '',
      title: '온라인 이벤트 진행',
      content: [
        '참가자 모집 & 등록 시스템',
        '온라인 안내 & 이벤트',
        '온라인 이벤트 연출 및 송출',
        '실시간 이벤트 관리',
      ],
    },
    {
      number: '',
      title: '사후 관리',
      content: ['참가자 만족도 설문 조사 ', '이벤트 데이터 구축 ', '결과 자료 제작', 'CRM TOOL'],
    },
  ],
};

export const peopleDiagram = {
  title: ['How we make', 'BE MOVED'],
  subtitle: ['감히 상상합니다.'],
  description: ['감동 메이커는 대중에게 새로운 감동을 ', '선사하기 위해 오늘도 감히 상상합니다.'],
  diagram: [
    { number: '01', title: 'DARE', content: ['한번도 경험하지 못한 것을 ', '감히 도전합니다.'] },
    { number: '02', title: 'IMAGINE', content: ['새로운 콘텐츠를 위해 끊임없이 ', '상상합니다.'] },
    { number: '03', title: 'DREAM', content: ['미래를 바라보며 ', '꿈을 꿉니다.'] },
  ],
};
