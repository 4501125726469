import React from 'react';
import { CircularProgress } from '@material-ui/core';

function Loading() {
  return (
    <div className="LoadingWrapper">
      {/* <CircularProgress /> */}
      <img src="/img/loading_2.gif" alt="loading" />
    </div>
  );
}

export default Loading;
