import { SET_LOADING, CANCEL_LOADING, Common, SET_HEADER_COLOR } from './types';
import { CommonActionTypes } from './actionTypes';

const initialState: Common = {
  loading: false,
  headerColor: '#000',
};

const commonReducer = (state = initialState, action: CommonActionTypes) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_HEADER_COLOR:
      return {
        ...state,
        headerColor: action.headerColor,
      };
    default:
      return state;
  }
};

export default commonReducer;
