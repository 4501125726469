export const portfolioMainTag = [
  'All Projects',
  'Hybrid Event',
  'Online Live System',
  'Virtual Studio',
  'Original IP',
  'Design',
  'IT Solution',
];

export const portfolioSubTag = ['Digital', 'Promotion', 'International', 'Space', 'Festival'];

export const MainMenuList = [
  { url: '/about', name: 'About' },
  { url: '/business', name: 'Business' },
  { url: '/portfolio', name: 'Portfolio' },
  { url: '/people', name: 'People' },
  { url: '/contact', name: 'Contact' },
];

export const BusinessMenuList = [
  { url: '/business/hybridevent', name: 'Hybrid Event' },
  { url: '/business/onlinelivesystem', name: 'Online Live System' },
  { url: '/business/virtualstudio', name: 'Virtual Studio' },
  { url: '/business/design', name: 'Design' },
  { url: '/business/originalip', name: 'Original IP' },
  { url: '/business/itsolution', name: 'IT Solution' },
  { url: '/business/fmlab', name: 'FM Lab' },
];

export const businessAreaList = [
  {
    name: 'Hybrid Event',
    content1: '온오프라인 채널을 활용한',
    content2: '융복합이벤트',
    area: ['Digital', '/ Promotion', '/ International', ' / Space', ' / Festival'],
  },
  {
    name: 'Online Live System',
    content1: '이벤트 연출 기획 및 장비를 활용한',
    content2: '비대면 라이브 시스템',
    area: ['B.BLOCK'],
  },
  {
    name: 'Virtual Studio',
    content1: '실감형 콘텐츠 구현이 가능한',
    content2: '가상 스튜디오',
    area: ['V.Studio'],
  },
  {
    name: 'Design',
    content1: '브랜드를 보여주는',
    content2: '감각적인 디자인',
    area: ['Branding', '/ UX & UI', '/ Goods & Product', '/ Character', '/ Online Contents'],
  },
  {
    name: 'Original IP',
    content1: '자체 콘텐츠 IP기반의 문화 브랜드',
    content2: '(캐릭터, MD, 전시 등)',
    area: ['HI Island'],
  },
  {
    name: 'IT Solution',
    content1: '웹, 모바일 기반의',
    content2: '기술 솔루션',
    area: ['Web & Responsive Page', '/ Mobile Application'],
  },
];
