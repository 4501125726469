import React, { useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import useUser from 'hooks/user/useUser';
import useCommon from 'hooks/common/useCommon';
import Loading from 'components/common/Loading';
import Header from 'components/common/Header';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import styled from 'styled-components';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

function MainLayout({ component: Component, ...rest }: IProps) {
  const { userModal } = useUser();
  const { portfolioModal } = usePortfolio();
  const { loading, onSetHeaderColor } = useCommon();
  const location = useLocation();
  const [background, setBackground] = useState('black');
  const [color, setColor] = useState('#ffffff');

  useEffect(() => {
    if (loading || userModal || portfolioModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [loading, userModal, portfolioModal]);

  useEffect(() => {
    setColors();
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    navigator.userAgent.toLocaleLowerCase().indexOf('iphone') > -1 &&
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 800);
  }, [location.key]);

  useEffect(() => {
    window.onbeforeunload = function () {
      if (navigator.userAgent.toLocaleLowerCase().indexOf('iphone') === -1) {
        window.scrollTo(0, 0);
        document.body.scrollTo(0, 0);
        document.documentElement.scrollTo(0, 0);
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
          document.body.scrollTo(0, 0);
          document.documentElement.scrollTo(0, 0);
        }, 800);
      }
    };

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, []);

  const setColors = () => {
    const path =
      location.pathname.lastIndexOf('/') === location.pathname.length - 1 &&
      location.pathname.length != 1
        ? location.pathname.slice(0, -1)
        : location.pathname;
    switch (path) {
      case '/':
        setBackground('whiteLine');
        setColor('#000000');
        onSetHeaderColor({ headerColor: '#000' });
        break;
      case '/about':
        setBackground('blackLine');
        setColor('#ffffff');
        onSetHeaderColor({ headerColor: '#000' });
        break;
      case '/business':
        setBackground('whiteLine');
        setColor('#000000');
        onSetHeaderColor({ headerColor: '#fff' });
        break;
      case '/business/hybridevent':
        setBackground('whiteLine');
        setColor('#FF4929');
        onSetHeaderColor({ headerColor: '#fff' });
        break;
      case '/business/onlinelivesystem':
        setBackground('whiteLine');
        setColor('#8700ff');
        onSetHeaderColor({ headerColor: '#fff' });
        break;
      case '/business/virtualstudio':
        setBackground('whiteLine');
        setColor('#000000');
        onSetHeaderColor({ headerColor: '#fff' });
        break;
      case '/business/originalip':
        setBackground('whiteLine');
        setColor('#FF9F9F ');
        onSetHeaderColor({ headerColor: '#fff' });
        break;
      case '/business/itsolution':
        setBackground('blackLine');
        setColor('#ccff00');
        onSetHeaderColor({ headerColor: '#000' });
        break;
      case '/business/design':
        setBackground('whiteLine');
        setColor('#0040ff');
        onSetHeaderColor({ headerColor: '#ff4929' });
        break;
      case '/business/fmlab':
        setBackground('blackLine');
        setColor('#ffffff');
        onSetHeaderColor({ headerColor: '#000' });
        break;
      case '/portfolio':
        setBackground('blackLine');
        setColor('#ffffff');
        onSetHeaderColor({ headerColor: '#000' });
        break;
      case '/people':
        setBackground('blackLine');
        setColor('#ffffff');
        onSetHeaderColor({ headerColor: '#fff' });
        break;
      case '/contact':
        setBackground('blackLine');
        setColor('#ffffff');
        onSetHeaderColor({ headerColor: '#000' });
        break;
      default:
        onSetHeaderColor({ headerColor: '#fff' });
        break;
    }
  };

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div key={location.key} className="pageTransition">
          {loading && <Loading />}
          <Header pathName={location.pathname} />
          <Component {...matchProps} />
          <Background className="fixedBg" color={color}>
            {background === 'blackLine' && <img src="/img/bg_black_line.png" alt="line bg" />}
            {background === 'whiteLine' && <img src="/img/bg_white_line.png" alt="line bg" />}
          </Background>
        </div>
      )}
    />
  );
}

interface BackgroundProps {
  color: string;
}

const Background = styled.div<BackgroundProps>`
  background-color: ${({ color }) => color};
`;

export default MainLayout;
