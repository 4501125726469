import { Link } from 'react-scroll';

interface BusinessSubMenuProps {
  menuList: Array<{ target: string; title: string }>;
  focus: string;
}

function BusinessSubMenu({ menuList, focus }: BusinessSubMenuProps) {
  return (
    <div className="pageSubMenu">
      <ul>
        {menuList.map((data) => (
          <li className={focus === data.target ? 'focused' : ''} key={data.target}>
            <Link to={data.target} smooth={true} duration={500}>
              {data.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BusinessSubMenu;
