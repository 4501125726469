import React, { useState } from 'react';

import PortfolioFilter from 'components/portfolio/PortfolioFilter';
import PortfolioList from 'components/portfolio/PortfolioList';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import PortfolioModal from 'components/portfolio/PortfolioModal';
import SubMenu from 'components/common/SubMenu';

function Portfolio() {
  const storageUser = localStorage.getItem('portfolio');
  const { portfolioModal, onOpenPortfolioModal } = usePortfolio();
  const [subMenu, setSubMenu] = useState(false);
  const addPortfolio = () => {
    onOpenPortfolioModal({ mode: 'add' });
  };

  const onHandleSubMenu = () => {
    setSubMenu(!subMenu);
  };

  return (
    <>
      <div className="portfolioPageWrapper">
        <PortfolioFilter onHandleSubMenu={onHandleSubMenu} />
        <PortfolioList />
        {portfolioModal && <PortfolioModal />}
      </div>
      {subMenu && <SubMenu onHandleSubMenu={onHandleSubMenu} />}
    </>
  );
}

export default Portfolio;
