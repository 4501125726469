import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from 'store';
import {
  closePortfolioModal,
  deletePortfolio,
  getBusinessPortfolioList,
  getPortfolioDetail,
  getPortfolioList,
  insertPortfolio,
  openPortfolioModal,
  resetPortfolio,
  updatePortfolio,
} from 'store/portfolio/actions';
import { PortfolioInfo } from 'store/portfolio/types';

export default function usePortfolio() {
  const {
    portfolioModal,
    portfolioMode,
    portfolio,
    portfolioList,
    maintag,
    subtag,
    name,
    businessPortfolioList,
    totalCount,
    nowPage,
  } = useSelector((state: RootState) => state.portfolio);
  const dispatch = useDispatch();

  const onGetPortfolioList = useCallback(
    ({
      maintag,
      subtag,
      name,
      nowPage,
    }: {
      maintag: string;
      subtag: Array<string>;
      name: string;
      nowPage: number;
    }) => dispatch(getPortfolioList({ maintag, subtag, name, nowPage })),
    [dispatch]
  );

  const onGetPortfolioDetail = useCallback(
    ({ _id }: { _id: string }) => {
      dispatch(getPortfolioDetail({ _id }));
    },
    [dispatch]
  );

  const onInsertPortfolio = useCallback(
    ({
      portfolio,
      addImg,
      thumbnail,
    }: {
      portfolio: PortfolioInfo;
      addImg: Array<File>;
      thumbnail: string;
    }) => {
      dispatch(insertPortfolio({ portfolio, addImg, thumbnail }));
    },
    [dispatch]
  );

  const onUpdatePortfolio = useCallback(
    ({
      portfolio,
      addImg,
      thumbnail,
    }: {
      portfolio: PortfolioInfo;
      addImg: Array<File>;
      thumbnail: string;
    }) => {
      dispatch(updatePortfolio({ portfolio, addImg, thumbnail }));
    },
    [dispatch]
  );

  const onDeletePortfolio = useCallback(
    ({ _id }: { _id: string }) => {
      dispatch(deletePortfolio({ _id }));
    },
    [dispatch]
  );

  const onOpenPortfolioModal = useCallback(
    ({ mode }: { mode: string }) => {
      dispatch(openPortfolioModal({ mode }));
    },
    [dispatch]
  );

  const onClosePortfolioModal = useCallback(() => {
    dispatch(closePortfolioModal());
  }, [dispatch]);

  const onGetBusinessPortfolioList = useCallback(
    ({ maintag }: { maintag: string }) => {
      dispatch(getBusinessPortfolioList({ maintag }));
    },
    [dispatch]
  );

  const onResetPortfolio = useCallback(() => {
    dispatch(resetPortfolio());
  }, [dispatch]);

  return {
    portfolioModal,
    portfolioMode,
    portfolio,
    portfolioList,
    maintag,
    subtag,
    name,
    businessPortfolioList,
    totalCount,
    nowPage,
    onGetPortfolioList,
    onGetPortfolioDetail,
    onInsertPortfolio,
    onUpdatePortfolio,
    onDeletePortfolio,
    onOpenPortfolioModal,
    onClosePortfolioModal,
    onGetBusinessPortfolioList,
    onResetPortfolio,
  };
}
