import React, { useState } from 'react';

import PortfolioFilter from 'components/portfolio/PortfolioFilter';
import PortfolioList from 'components/portfolio/PortfolioList';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import PortfolioModal from 'components/portfolio/PortfolioModal';
import SubMenu from 'components/common/SubMenu';

function AdminPortfolio() {
  const storageUser = localStorage.getItem('portfolio');
  const { portfolioModal, onOpenPortfolioModal } = usePortfolio();
  const [subMenu, setSubMenu] = useState(false);
  const addPortfolio = () => {
    onOpenPortfolioModal({ mode: 'add' });
  };

  const onHandleSubMenu = () => {
    setSubMenu(!subMenu);
  };

  return (
    <>
      <div className="portfolioPageWrapper">
        {storageUser === 'yes' && (
          <button className="addPortfolio" onClick={addPortfolio}>
            <img src="/img/add.svg" alt="add portfolio" />
          </button>
        )}
        <PortfolioFilter onHandleSubMenu={onHandleSubMenu} />
        <PortfolioList />
        {portfolioModal && <PortfolioModal />}
      </div>
      {subMenu && <SubMenu onHandleSubMenu={onHandleSubMenu} />}
    </>
  );
}

export default AdminPortfolio;
