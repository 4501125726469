import gsap from 'gsap';
import useOnScreen from 'lib/useOnScreen';
import React, { Fragment, useEffect, useRef, useState } from 'react';

interface FMLabPageProps {
  title: Array<string>;
  content: Array<string>;
  functionList: Array<{ image: string; title: string; detail: Array<string> }>;
  linkList: Array<{ title: string; detail: Array<string> }>;
}

function FMLabPage({ title, content, functionList, linkList }: FMLabPageProps) {
  const pageRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const functionRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLDivElement>(null);

  const onScreen = useOnScreen(titleRef);

  const tween = gsap.timeline({ paused: true });
  const [animation, setAnimation] = useState<any>(null);

  useEffect(() => {
    if (onScreen) {
      console.log(title[0]);
      animation.play();
    }
  }, [onScreen]);

  useEffect(() => {
    if (titleRef.current && functionRef.current && circleRef.current && linkRef.current) {
      setAnimation(
        tween
          .to(titleRef.current, { y: -20, autoAlpha: 1 })
          .to(functionRef.current, { y: -20, autoAlpha: 1 })
          .to(circleRef.current, { y: -20, autoAlpha: 1 })
          .to(linkRef.current, { y: -20, autoAlpha: 1 })
          .pause()
      );
    }
  }, [titleRef.current, functionRef.current, circleRef.current, linkRef.current]);

  return (
    <div className="contentWrapper" id={title[0] + '_title'} ref={pageRef}>
      <div className="descriptionWrapper" id={title[0] + '_title'} ref={titleRef}>
        <div className="title">
          {title.map((data) => (
            <React.Fragment key={data}>
              {data}
              <br />
            </React.Fragment>
          ))}
        </div>
        <div className="content">
          {content.map((data) => (
            <Fragment key={data}>
              {data}
              <br />
            </Fragment>
          ))}
        </div>
      </div>
      <div className="refImgWrapper">
        <div className="rowWrapper" id={title[0] + '_function'} ref={functionRef}>
          {functionList.map((data) => (
            <div className="function" key={data.image}>
              <div className="pictogram">
                <img src={data.image} alt="pictogram" />
              </div>
              <div className="title">{data.title}</div>
              <div className="detail">
                {data.detail.map((detail) => (
                  <Fragment key={detail}>
                    {detail}
                    <br />
                  </Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="rowWrapper circles" id={title[0] + '_circle'} ref={circleRef}>
          <div className="pictogram">
            <img src="/img/circles.svg" alt="pictogram" />
          </div>
        </div>
        <div className="rowWrapper" ref={linkRef} id={title[0] + '_link'}>
          {linkList.map((data) => (
            <div className="link" key={data.title}>
              <div className="title">
                {data.title.split(' ').map((words) => (
                  <Fragment key={words}>
                    {words}
                    <br />
                  </Fragment>
                ))}
              </div>
              <div className="detail">
                {data.detail.map((detail) => (
                  <Fragment key={detail}>
                    {detail}
                    <br />
                  </Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FMLabPage;
