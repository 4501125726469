import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { Router } from 'react-router-dom';
import { browserHistory } from 'lib/historyUtils';
import 'intersection-observer';

import rootReducer, { rootSaga } from './store';
import BrowerNotSupport from 'pages/BrowerNotSupport';
// import { refresh } from 'store/user/actions';

const sagaMiddleware = createSagaMiddleware();
const logger = process.env.NODE_ENV === 'development' ? createLogger() : null;

const store = createStore(
  rootReducer,
  // process.env.NODE_ENV === 'development' && logger
  //   ? applyMiddleware(sagaMiddleware, logger)
  applyMiddleware(sagaMiddleware)
);

function loadUser() {
  try {
    const user = localStorage.getItem('portfolio');
    if (user !== 'yes') return;
  } catch (e) {
    console.log(e);
  }
}
const browse = navigator.userAgent.toLowerCase();
sagaMiddleware.run(rootSaga);
loadUser();

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      {browse.indexOf('mise') > -1 || browse.indexOf('trident') > -1 ? (
        <BrowerNotSupport />
      ) : (
        <App />
      )}
    </Router>
  </Provider>,
  document.getElementById('root')
);
