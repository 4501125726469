import useOnScreen from 'lib/useOnScreen';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Element } from 'react-scroll';
import gsap from 'gsap';

interface BusinessTextImage2Props {
  title: Array<string>;
  content: Array<string>;
  footer: { type: String; content: Array<{ content: string; href: string }> };
  images: Array<{ url: string; portfolioId: string }>;
  target: string;
  idx: number;
  setFocus: (focus: string) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

function BusinessTextImage2({
  title,
  content,
  footer,
  images,
  target,
  idx,
  setFocus,
  onClick,
}: BusinessTextImage2Props) {
  const divRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(divRef, '0px', 0.3);
  const imageClassName =
    images.length > 4
      ? 'triple'
      : images.length === 4
      ? 'quadruple'
      : images.length === 3
      ? 'triple'
      : 'double';

  const tween = gsap.timeline({ paused: true });
  const [animation, setAnimation] = useState<any>(null);
  useEffect(() => {
    if (onScreen) {
      setFocus('content' + idx);
      animation.play();
    }
  }, [onScreen]);

  useEffect(() => {
    if (titleRef.current && imageRef.current) {
      setAnimation(
        tween
          .to(titleRef.current, { y: -20, autoAlpha: 1 })
          .to(imageRef.current, { y: -20, autoAlpha: 1 })
          .pause()
      );
    }
  }, [titleRef.current, imageRef.current]);

  return (
    <Element name={'content' + idx}>
      <div className="contentWrapper" ref={divRef}>
        <div className="descriptionWrapper" ref={titleRef}>
          <div className="title">
            {title.map((data) => (
              <Fragment key={data}>
                {data}
                <br />
              </Fragment>
            ))}
          </div>
          <div className="content">
            {content.map((data, idx) => (
              <Fragment key={data}>
                {idx === 0 && target === 'originalIP' ? <span>{data}</span> : data}
                <br />
              </Fragment>
            ))}
          </div>
          <div className="content footer">
            {footer.type === 'text' &&
              footer.content.map((data) => (
                <Fragment key={data.content}>
                  {data.content}
                  <br />
                </Fragment>
              ))}
            {footer.type === 'link' &&
              footer.content.map((data) => (
                <a href={data.href} key={data.content} target="_blank">
                  {data.content}
                  <img src="/img/link.svg" alt="link" />{' '}
                </a>
              ))}
          </div>
        </div>
        <div className={'refImgWrapper ' + imageClassName} ref={imageRef}>
          {images.map((data, idx) => (
            <div
              className={
                target === 'originalIP' ? 'hiRefImg ' + data.url : 'designRefImg ' + data.url
              }
              key={target + data + idx}
              data-portfolioid={data.portfolioId}
              onClick={data.portfolioId ? onClick : undefined}
            ></div>
          ))}
        </div>
      </div>
    </Element>
  );
}

export default BusinessTextImage2;
