import useOnScreen from 'lib/useOnScreen';
import gsap from 'gsap';
import React, { Fragment, useEffect, useRef, useState } from 'react';

interface DiagramProps {
  title: Array<string>;
  subtitle: Array<string>;
  description: Array<string>;
  diagram: Array<{ number: string; title: string; content: Array<string> }>;
}

function Diagram({ title, subtitle, description, diagram }: DiagramProps) {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const diagramRef1 = useRef<HTMLDivElement>(null);
  const diagramRef2 = useRef<HTMLDivElement>(null);
  const diagramRef3 = useRef<HTMLDivElement>(null);

  const onScreen = useOnScreen(descriptionRef);

  const tween = gsap.timeline({ paused: true });
  const [animation, setAnimation] = useState<any>(null);

  useEffect(() => {
    if (onScreen) {
      animation.play();
    }
  }, [onScreen]);

  useEffect(() => {
    if (
      descriptionRef.current &&
      diagramRef1.current &&
      diagramRef2.current &&
      diagramRef3.current
    ) {
      setAnimation(
        tween
          .to(descriptionRef.current, { y: -20, autoAlpha: 1 })
          .to(diagramRef1.current, { y: -20, autoAlpha: 1 })
          .to(diagramRef2.current, { y: -20, autoAlpha: 1 })
          .to(diagramRef3.current, { y: -20, autoAlpha: 1 })
          .pause()
      );
    }
  }, [descriptionRef.current, diagramRef1.current, diagramRef2.current, diagramRef3.current]);

  return (
    <>
      <div className="description" ref={descriptionRef}>
        <div className="title">
          {title.map((data) => (
            <Fragment key={data}>
              {data}
              <br />
            </Fragment>
          ))}
        </div>
        <div className="subTitle">
          {subtitle.map((data) => (
            <Fragment key={data}>
              {data}
              <br />
            </Fragment>
          ))}
        </div>
        {description.map((data) => (
          <Fragment key={data}>
            {data}
            <br />
          </Fragment>
        ))}
      </div>
      <div className="diagramWrapper">
        {diagram.map((data, idx) => (
          <div
            className="diagram"
            ref={idx === 0 ? diagramRef1 : idx === 1 ? diagramRef2 : diagramRef3}
            key={data.title}
          >
            <div className="diagramContentWrapper">
              {data.number && <div className="diagramNumber">{data.number}</div>}
              <div className="diagramTitle">{data.title}</div>
              <div className="diagramContent">
                {data.content.map((text) => (
                  <Fragment key={text}>
                    {text}
                    <br />
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Diagram;
