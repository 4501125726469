import { Dialog, DialogContent } from '@material-ui/core';
import { Chip, FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';
import useUser from 'hooks/user/useUser';
import React, { useState } from 'react';
import useInput from 'lib/useInput';
import PrivacyPoliticModal from 'components/common/PrivacyPoliticModal';
import useTextAreaInput from 'lib/useTextAreaInput';

function ContactModal() {
  const { userModal, onCloseUserModal, onSendEmail } = useUser();
  const [name, setName] = useInput('');
  const [org, setOrg] = useInput('');
  const [email, setEmail] = useInput('');
  const [phone, setPhone] = useInput('');
  const [open, setOpen] = useState(false);

  const [content, setContent] = useTextAreaInput('');
  const [checked, setChecked] = useState(false);

  const [business, setBusiness] = useState<Array<string>>([]);
  const [file, setFile] = useState<File>();

  const businessList = [
    'Hybrid Event',
    'Online Live System',
    'Virtual Studio',
    'Design',
    'Original IP',
    'IT Solution',
    'ETC',
  ];
  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    const { value } = event.target;
    value && setBusiness(value);
  };

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setChecked(checked);
  };

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : null;
    if (files) {
      const oversize = files.filter((file) => file.size > 10485760); //10MB

      if (oversize.length > 0) {
        alert('첨부파일의 사이즈는 파일당 10MB를 넘을 수 없습니다.');
        return;
      }

      setFile(files[0]);
    }
  };

  const removeFile = () => {
    setFile(undefined);
  };

  const onSend = () => {
    if (!checked) {
      alert('개인 정보 정책에 동의해야 발송이 가능합니다.');
      return;
    }

    if (!name.trim()) {
      alert('담당자 성함을 입력해주세요.');
      return;
    }

    if (!org.trim()) {
      alert('담당자 소속을 입력해주세요.');
      return;
    }

    if (!phone.trim()) {
      alert('담당자 연락처를 입력해주세요.');
      return;
    }

    if (!email.trim()) {
      alert('담당자 이메일 주소를 입력해주세요.');
      return;
    }

    if (business.length < 1) {
      alert('사업영역을 선택해주세요.');
      return;
    }
    if (!content.trim()) {
      alert('프로젝트 혹은 제안, 회사 소개 등 자세한 내용을 입력해주세요.');
      return;
    }

    checked &&
      onSendEmail({
        to: business.join(','),
        name,
        company: org,
        email,
        phone,
        text: content,
        file,
      });
  };

  const handleModal = () => {
    setOpen(!open);
  };

  const onBusinessClick = (e: React.MouseEvent<HTMLElement>) => {
    const targetId = e.currentTarget.getAttribute('data-id');

    if (!targetId) {
      return;
    }

    if (business.filter((data) => data === targetId).length > 0) {
      setBusiness(business.filter((data) => data !== targetId));
    } else {
      setBusiness(business.concat(targetId));
    }
  };

  return (
    <Dialog open={userModal} onClose={onCloseUserModal} disableBackdropClick disableEscapeKeyDown>
      <button onClick={onCloseUserModal} className="closeBtn">
        <img src="/img/close.svg" alt="close" />
      </button>
      <DialogContent className="mailWrapper">
        {/* <div className="mailWrapper"> */}
        {/* <div>Conctact Us</div> */}
        {/* <div className="from"> */}
        <div className="title">
          Contact Us<span>*는 필수사항입니다.</span>
        </div>
        <input type="text" placeholder="*담당자 성함" value={name} onChange={setName} />
        <input type="text" placeholder="*담당자 소속" value={org} onChange={setOrg} />
        <input type="text" placeholder="*담당자 연락처" value={phone} onChange={setPhone} />
        <input type="text" placeholder="*담당자 이메일 주소" value={email} onChange={setEmail} />
        {/* </div> */}
        {/* <div className="to"> */}
        {/* <div className="title">
              TO<span>webmaster@promotion.co.kr</span>
            </div> */}
        {/* <div> */}
        <label className="businessArea">*사업영역(다중선택 가능)</label>
        <div className="businessArea">
          {businessList.map((data) => (
            <BusinessSpan
              key={data}
              onClick={onBusinessClick}
              data-id={data}
              checked={business.filter((payload) => payload === data).length > 0}
            >
              {data}
            </BusinessSpan>
          ))}
        </div>
        {/* </div> */}
        {/* <input
              type="text"
              placeholder="*프로젝트 문의, 제안, 회사 소개 등 자세한 내용을 입력해주세요"
              value={content}
              onChange={setContent}
            /> */}
        <textarea
          onChange={setContent}
          rows={5}
          placeholder="*프로젝트 문의, 제안, 회사 소개 등 자세한 내용을 입력해주세요"
        />
        <div className="attachWrapper">
          <input id="attach" type="file" onChange={uploadFile} />
          <label htmlFor="attach">
            첨부파일 업로드(10MB 이하)
            <label htmlFor="attach">
              <button>
                <img src="/img/add.svg" alt="add attach" />
              </button>
            </label>
          </label>
          {file && (
            <div className="attachFile">
              <span>{file.name}</span>
              <button onClick={removeFile}>
                <img src="/img/add.svg" alt="remove attach" />
              </button>
            </div>
          )}
        </div>
        {/* </div> */}
        <div className="agreementWrapper">
          <label onClick={handleModal}>*개인정보 정책에 동의합니다.</label>
          <input id="agreement" type="checkbox" onChange={handleChecked} checked={checked} />
        </div>
        <SendButton
          className="send"
          checked={
            checked &&
            name.trim() !== '' &&
            org.trim() !== '' &&
            email.trim() !== '' &&
            phone.trim() !== '' &&
            business.length > 0 &&
            content.trim() !== ''
          }
          onClick={onSend}
        >
          SEND
        </SendButton>
        {/* </div> */}
      </DialogContent>
      {open && <PrivacyPoliticModal open={open} onClose={handleModal} />}
    </Dialog>
  );
}

interface ButtonProps {
  checked: boolean;
}

const SendButton = styled.button<ButtonProps>`
  background-color: ${({ checked }) => (checked ? 'black' : 'gray')};
  color: white;
`;

interface BusinessProps {
  checked: boolean;
}

const BusinessSpan = styled.span<BusinessProps>`
  background: ${({ checked }) =>
    checked
      ? `linear-gradient(90deg,
            rgba(0, 185, 239, 1) 0%,
            rgba(255, 69, 210, 1) 100%
          );`
      : `#cecece`};
  color: ${({ checked }) => (checked ? `white` : `black`)};
`;

export default ContactModal;
