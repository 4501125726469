import { sendContact } from 'lib/api/userApi';
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import { SET_LOADING, CANCEL_LOADING } from './types';

//공통인 로딩
function* setLoading() {
  console.log('setLoading activated');
  yield put({
    type: SET_LOADING,
  });
}

function* cancelLoading() {
  console.log('cancelLoading activated');
  yield put({
    type: CANCEL_LOADING,
  });
}

//when user expired or logged out
export function removeUserInfo() {
  console.log('removeuser started');
  try {
    window.localStorage.removeItem('portfolio');
    window.location.reload();
  } catch (e) {
    console.log('localstorage is not working');
  }
}

export function setUserInfo() {
  try {
    localStorage.setItem('portfolio', 'yes');
  } catch (e) {
    console.log('localstorage is not working');
  }
}

export function* handleError(error: any) {
  if (error.response && error.response.status === 401) {
    yield call(removeUserInfo);
  } else {
    console.log(error);
    const { message } = error.response.data;
    alert(message ? message : '처리에 실패하였습니다.');
  }
}

function* setCommonRequest() {
  yield takeEvery(
    (action: any) => action.type.substring(action.type.lastIndexOf('_') + 1) === 'REQUEST',
    setLoading
  );
  yield takeEvery(
    (action: any) =>
      action.type.substring(action.type.lastIndexOf('_') + 1) === 'FAILURE' ||
      action.type.substring(action.type.lastIndexOf('_') + 1) === 'RESET' ||
      action.type.substring(action.type.lastIndexOf('_') + 1) === 'SUCCESS',
    cancelLoading
  );
}
export default function* commonSaga() {
  yield all([setCommonRequest()]);
}
