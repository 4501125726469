import BusinessHeader from 'components/business/BusinessHeader';
import BusinessTextImage from 'components/business/BusinessTextImage';
import BusinessArrow from 'components/common/BusinessArrow';
import Diagram from 'components/common/Diagram';
import { onlineLiveInfo, onlineLiveSystemDiagram } from 'lib/values/businessInfo';
import React from 'react';

function OnlineLiveSystem() {
  return (
    <div className="onlineLiveWrapper scrolling">
      <BusinessHeader
        target="design"
        headerTitle={onlineLiveInfo.headerTitle}
        subTitle={onlineLiveInfo.subTitle}
        contents={onlineLiveInfo.contents}
      />
      <div className="contentSection">
        {onlineLiveInfo.mainContents.map((content, idx) => (
          <BusinessTextImage
            type={content.type}
            idx={idx + 1}
            first={content.first}
            second={content.second}
            image={content.image || ''}
            target="onlineLiveSystem"
          />
        ))}

        <div className="content sixth">
          <Diagram
            title={onlineLiveSystemDiagram.title}
            subtitle={onlineLiveSystemDiagram.subtitle}
            description={onlineLiveSystemDiagram.description}
            diagram={onlineLiveSystemDiagram.diagram}
          />
        </div>
      </div>
    </div>
  );
}

export default OnlineLiveSystem;
