import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { useState } from 'react';
import ko from 'date-fns/locale/ko';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Calendar } from 'react-date-range';

interface DatePickerProps {
  value: Date | null;
  onChange: ({ id, date }: { id: string; date: Date | null }) => void;
  disabled?: boolean;
  open: boolean;
  id: string;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

function DatePicker({ value, onChange, open, id, onClose }: DatePickerProps) {
  const [state, setState] = useState(
    value ? (typeof value === 'string' ? new Date(value) : value) : null
  );

  const onSubmit = () => {
    onChange({ id, date: state });
  };

  const onSelect = (date: Date | null) => {
    setState(date);
  };

  return (
    <Dialog
      className="datePickerWrapper"
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      id={id}
    >
      <DialogTitle>달력 선택</DialogTitle>
      <DialogContent>
        <Calendar
          onChange={onSelect}
          date={state}
          locale={ko}
          dateDisplayFormat="yyyy년 MM월 dd일"
          monthDisplayFormat="yyyy년 MM월"
          months={1}
          direction="horizontal"
          color="#f36c21"
          rangeColors={['f36c21']}
        />
      </DialogContent>
      <DialogActions>
        <button onClick={onSubmit}>확인</button>
        <button onClick={onClose}>취소</button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(DatePicker);
