import BusinessHeader from 'components/business/BusinessHeader';
import VirtualStudioContent2 from 'components/business/VirtualStudioContent2';
import gsap from 'gsap';
import { push } from 'lib/historyUtils';
import useOnScreen from 'lib/useOnScreen';
import { virtualInfo } from 'lib/values/businessInfo';
import React, { useEffect, useRef } from 'react';
import VirtualStudioContent1 from '../../components/business/VirtualStudioContent1';

const content1List = [
  {
    img: '/img/vstudio_img2.jpg',
    title: ['크로마키', '호리존트(5x5x3m)'],
    sutTitle: [
      '호리존트 촬영을 위해 코너를 이음새 없이 ',
      '작업하여 무한의 공간 연출이 가능합니다.',
    ],
  },
  {
    img: '/img/vstudio_img3.jpg',
    title: ['전용 조명', '시스템'],
    sutTitle: [
      '크로마키 촬영을 위한 스튜디오 전용 조명 ',
      '시스템을 구비하였습니다. 다양한 조명 연출을 ',
      '통해 더욱 실감나는 씬을 구현합니다.',
    ],
  },
  {
    img: '/img/vstudio_img4.jpg',
    title: ['컨트롤 공간'],
    sutTitle: [
      '원활한 커뮤니케이션과 모니터링을 위한 별도의 ',
      '콘솔 조정실을 갖추고 있습니다. 실시간 ',
      '모니터링을 바탕으로 보다 안정적인 연출 및 ',
      '송출을 진행합니다.',
    ],
  },
];

const content2List = [
  {
    img: '/img/vstudio_img8.jpg',
    title: ['Space', 'Expansion'],
    subTitle: [
      '실제 공간의 제약을 뛰어넘는 시각적 연출 효과를 ',
      '통해 영상의 몰입을 극대화 합니다.',
    ],
    imgTop: true,
  },
  {
    img: '/img/vstudio_img5.jpg',
    title: ['AR/VR'],
    subTitle: ['증강 현실(AR) 기술을 활용한 오브젝트를 통해 ', '사실적인 3D 경험을 제공합니다.'],
    imgTop: false,
  },
  {
    img: '/img/vstudio_img6.jpg',
    title: ['Webinar Platform'],
    subTitle: [
      '가상 스튜디오와 화상 회의 플랫폼 간의 실시간 ',
      '양방향 커뮤니케이션이 가능합니다.',
    ],
    imgTop: true,
  },
  {
    img: '/img/vstudio_img7.jpg',
    title: ['Live', 'Streaming'],
    subTitle: [
      '자체 스트리밍 시스템과 실시간 배경 합성 기술을 ',
      '통해 현장감 넘치는 라이브 스트리밍을 ',
      '진행합니다.',
    ],
    imgTop: false,
  },
];

function VirtualStudio() {
  const content1Ref = useRef<HTMLDivElement>(null);
  const content2ImgRef = useRef<HTMLDivElement>(null);
  const content2TitleRef = useRef<HTMLDivElement>(null);
  const content2SubTitleRef = useRef<HTMLDivElement>(null);
  const content2LinkRef = useRef<HTMLDivElement>(null);

  const content3Ref = useRef<HTMLDivElement>(null);
  const content4Ref = useRef<HTMLDivElement>(null);

  const content1OnScreen = useOnScreen(content1Ref, '0px', 0.3);
  const content2OnScreen = useOnScreen(content2TitleRef);
  const content3OnScreen = useOnScreen(content3Ref);
  const content4OnScreen = useOnScreen(content4Ref);

  useEffect(() => {
    if (content1OnScreen) {
      gsap.timeline().to(content1Ref.current, { y: -20, autoAlpha: 1 });
    }

    if (content2OnScreen) {
      gsap
        .timeline()
        .to(
          [
            content2ImgRef.current,
            content2TitleRef.current,
            content2SubTitleRef.current,
            content2LinkRef.current,
          ],
          { y: -20, autoAlpha: 1, stagger: 0.3 }
        );
    }

    if (content3OnScreen) {
      gsap.timeline().to(content3Ref.current, { y: -20, autoAlpha: 1 });
    }

    if (content4OnScreen) {
      gsap.timeline().to(content4Ref.current, { y: -20, autoAlpha: 1 });
    }
  }, [content1OnScreen, content2OnScreen, content3OnScreen, content4OnScreen]);

  const moveToPortfolio = () => {
    push('/portfolio?tag1=virtualStudio');
  };

  return (
    <div className="vStudioWrapper scrolling">
      <BusinessHeader
        target="vStudio"
        headerTitle={virtualInfo.headerTitle}
        subTitle={virtualInfo.subTitle}
        contents={virtualInfo.contents}
      />
      <div className="contentSection">
        <div className="content content_1" ref={content1Ref}>
          <div className="imgWrapper">
            {/* <iframe
              src="https://www.youtube.com/embed/8fMI6-PQ6yU?&playsinline=1&controls=1&rel=0&showinfo=0&modestbranding=1"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <video controls width="100%" height="100%" muted autoPlay>
              <source src="/img/studio_project.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="description">
            <div className="subTitle">Virtual Show with V.Studio</div>
          </div>
        </div>

        <div className="content content_2">
          <div className="imgWrapper" ref={content2ImgRef}>
            <img src="/img/vstudio_img1.jpg" alt="vstudio" />
          </div>
          <div className="description part_1" ref={content2TitleRef}>
            <div className="title">
              V.Studio <br /> Component
            </div>
          </div>
          <div className="description part_2" ref={content2SubTitleRef}>
            <div className="subTitle">
              당신의 상상을 <span>ON AIR</span> 하는 <br />
              스튜디오 공간과 솔루션을 <br />
              소개합니다.
            </div>
          </div>
          <div className="description part_3" ref={content2LinkRef}>
            <a onClick={moveToPortfolio}>
              PORTFOLIO <img src="/img/link.svg" alt="" />
            </a>
          </div>
        </div>
        <div className="content content_3">
          <div className="description" ref={content3Ref}>
            <div className="title">
              Studio <br />
              Equipment
            </div>
            전문 장비와 인력을 갖춘 자체 <br />
            스튜디오를 통해 고품질의 <br />
            실감형 콘텐츠를 제작합니다.
          </div>
          <div className="imgWrapper">
            {content1List.map((data) => (
              <VirtualStudioContent1 img={data.img} title={data.title} subTitle={data.sutTitle} />
            ))}
          </div>
        </div>
        <div className="content content_4">
          <div className="description" ref={content4Ref}>
            <div className="title">
              Virtual <br />
              Solution
            </div>
          </div>
          <div className="imgWrapper">
            {content2List.map((data) => (
              <VirtualStudioContent2
                img={data.img}
                title={data.title}
                subTitle={data.subTitle}
                imgTop={data.imgTop}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualStudio;
