export const designHeader = '#00ff44';
export const hybridHeader = '#00ffeb';
export const onlineLiveHeader = '#00ebff';
export const vStudioHeader = '#0062ff';
export const originalIpHeader = '#FFFF00';
export const fmLabHeader = '#FF4929';
export const itSolutionHeader = '#0062ff';
export const peolpleHeader = '#ffffff';


