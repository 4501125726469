import BusinessArrow from 'components/common/BusinessArrow';
import Popup from 'components/popup/Popup';
import useCommon from 'hooks/common/useCommon';
import usePopup from 'hooks/popup/usePopup';
import { push } from 'lib/historyUtils';
import React, { useEffect, useState } from 'react';

function MainPage() {
  const { onSetLoading, onCancelLoading, onSetHeaderColor } = useCommon();
  const { popupList, onGetPopupList } = usePopup();
  const [scrollShow, setScrollShow] = useState(true);
  const [arrowColor, setArrowColor] = useState('black');
  const [showPopup, setShowPopup] = useState(true);
  const popupShowAfter = localStorage.getItem('popupShowAfter');

  useEffect(() => {
    onSetLoading();
    window.addEventListener('message', receiveMessage);
    if (popupShowAfter && new Date(popupShowAfter) > new Date()) {
    } else {
      localStorage.removeItem('popupShowAfter');
      onGetPopupList({ nowPage: 0 });
    }
  }, []);

  const receiveMessage = (e: any) => {
    e.data.color && onSetHeaderColor({ headerColor: e.data.color });
    e.data === 'contact' && push('/contact');
    e.data.arrow === 'show' && setScrollShow(true);
    e.data.arrow === 'disappear' && setScrollShow(false);
    e.data.arrowColor && setArrowColor(e.data.arrowColor);
  };

  const onClosePopup = () => {
    setShowPopup(false);
  };

  const onPopupClick = () => {
    const today = new Date();
    const afterOneDay = new Date(today.setTime(today.getTime() + 24 * 60 * 60 * 1000));
    localStorage.setItem('popupShowAfter', afterOneDay.toString());
    onClosePopup();
  };

  return (
    <div className="App">
      <iframe
        src={
          process.env.NODE_ENV === 'development'
            ? 'http://localhost/mainpage'
            : process.env.REACT_APP_MAIN_PAGE
        }
        onLoad={() => setTimeout(onCancelLoading, 1000)}
      ></iframe>
      {popupList && popupList.length > 0 && showPopup && (
        <Popup onPopupClick={onPopupClick} onClosePopup={onClosePopup} />
      )}
      {scrollShow && <BusinessArrow path={arrowColor} />}
    </div>
  );
}

export default MainPage;
