import { BusinessMenuList } from 'lib/values/portfolioValues';
import React from 'react';
import { Link } from 'react-router-dom';

function Business() {
  return (
    <div className="businessSubMenu">
      {BusinessMenuList.map((data, idx) => (
        <Link className="menuBtnStyle" to={data.url} key={data.url}>
          <span>{idx >= 10 ? idx + 1 : '0' + idx}</span>
          {data.name}
        </Link>
      ))}
    </div>
  );
}
export default Business;
