import {
  CLOSE_POPUP_MODAL,
  DELETE_POPUP_REQUEST,
  INSERT_POPUP_REQUEST,
  LIST_POPUP_REQUEST,
  OPEN_POPUP_MODAL,
  UPDATE_POPUP_REQUEST,
} from './types';

export const openPopupModal = () => ({
  type: OPEN_POPUP_MODAL,
});

export const closePopupModal = () => ({
  type: CLOSE_POPUP_MODAL,
});

export const getPopupList = ({ nowPage, target }: { nowPage: number; target?: string }) => ({
  type: LIST_POPUP_REQUEST,
  nowPage,
  target,
});

export const insertPopup = ({
  addImg,
  url,
  expire,
}: {
  addImg: File;
  url: string;
  expire: Date | null;
}) => ({
  type: INSERT_POPUP_REQUEST,
  addImg,
  url,
  expire,
});

export const updatePopup = ({
  _id,
  addImg,
  img,
  url,
  expire,
}: {
  _id: string;
  addImg?: File;
  img: string;
  url: string;
  expire: Date | null;
}) => ({
  type: UPDATE_POPUP_REQUEST,
  _id,
  addImg,
  img,
  url,
  expire,
});

export const deletePopup = ({ _id }: { _id: string }) => ({
  type: DELETE_POPUP_REQUEST,
  _id,
});
