import BusinessHeader from 'components/business/BusinessHeader';
import BusinessSubMenu from 'components/business/BusinessSubMenu';
import HybridEventPage from 'components/business/HybridEventPage';
import PortfolioModal from 'components/portfolio/PortfolioModal';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import { hybridInfo } from 'lib/values/businessInfo';
import React, { useEffect, useState } from 'react';

const hybridSubMenu = [
  { target: 'Digital', title: 'Digital' },
  { target: 'Promotion', title: 'Promotion' },
  { target: 'International', title: 'International' },
  { target: 'Festival', title: 'Festival' },
  { target: 'Space', title: 'Space' },
];

function HybridEvent() {
  const [focus, setFocus] = useState('Digital');
  const {
    onGetBusinessPortfolioList,
    portfolioModal,
    onGetPortfolioDetail,
    businessPortfolioList,
  } = usePortfolio();

  useEffect(() => {
    onGetBusinessPortfolioList({ maintag: 'Hybrid Event' });
  }, []);

  const onClick = (id: string) => {
    onGetPortfolioDetail({ _id: id });
  };

  return (
    <div className="hybridWrapper noscolling">
      <BusinessHeader
        target="hybrid"
        headerTitle={hybridInfo.headerTitle}
        subTitle={hybridInfo.subTitle}
        contents={hybridInfo.contents}
      />
      <div className="contentSection">
        <div className="switchingPage">
          <BusinessSubMenu menuList={hybridSubMenu} focus={focus} />
          {hybridInfo.mainContents.map((data) => (
            <HybridEventPage
              key={data.target}
              target={data.target}
              title={data.title}
              subtitle1={data.subtitle1}
              // subtitle2={data.subtitle2}
              description={data.description}
              setFocus={setFocus}
              portfolioList={
                businessPortfolioList
                  ? businessPortfolioList.filter(
                      (portfolio) =>
                        portfolio.subtag.length > 0 && portfolio.subtag[0] === data.target
                    )
                  : []
              }
              onClick={onClick}
            />
          ))}
        </div>
      </div>
      {portfolioModal && <PortfolioModal />}
    </div>
  );
}

export default HybridEvent;
