import {
  designHeader,
  fmLabHeader,
  hybridHeader,
  itSolutionHeader,
  onlineLiveHeader,
  originalIpHeader,
  vStudioHeader,
  peolpleHeader,
} from 'lib/values/colorList';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

interface BusinessArrowProps {
  path: string;
}

function BusinessArrow({ path }: BusinessArrowProps) {
  const [color, setColor] = useState('#fff ');
  const [visibility, setVisibility] = useState('visible');

  const setArrowVisibility = () => {
    if (window.pageYOffset > 0) {
      setVisibility('hidden');
    } else {
      setVisibility('visible');
    }
  };

  useEffect(() => {
    path !== 'black' && path !== 'white' && window.addEventListener('scroll', setArrowVisibility);

    return () => {
      path !== 'black' &&
        path !== 'white' &&
        window.removeEventListener('scroll', setArrowVisibility);
    };
  }, []);

  useEffect(() => {
    switch (path) {
      case 'design':
        setColor(designHeader);
        break;
      case 'hybrid':
        setColor(hybridHeader);
        break;
      case 'onlineLive':
        setColor(onlineLiveHeader);
        break;
      case 'vStudio':
        setColor(vStudioHeader);
        break;
      case 'originalIp':
        setColor(originalIpHeader);
        break;
      case 'fmLab':
        setColor(fmLabHeader);
        break;
      case 'itSolution':
        setColor(itSolutionHeader);
        break;
      case 'poeple':
        setColor(peolpleHeader);
        break;
      case 'about':
        setColor(fmLabHeader);
        break;
      case 'black':
        setColor('#000000');
        break;
      case 'white':
        setColor('#ffffff');
        break;
    }
  }, [path]);

  return (
    <div className="downArrow">
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="57"
        height="57"
        viewBox="0 0 57 57"
        visibility={visibility}
      >
        <path
          fill={color}
          fillRule="nonzero"
          d="M46.51 16.94L28 35.95 9.49 16.94 6 20.27 28 42.94 50 20.27z"
        />
      </Svg>
    </div>
  );
}

interface SvgProps {
  visibility: string;
}

const Svg = styled.svg<SvgProps>`
  visibility: ${({ visibility }) => visibility};
`;

export default BusinessArrow;
