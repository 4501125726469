import {
  CLOSE_PORTFOLIO_MODAL,
  DELETE_PORTFOLIO_REQUEST,
  DETAIL_PORTFOLIO_REQUEST,
  INSERT_PORTFOLIO_REQUEST,
  LIST_BUSINESS_PORTFOLIO_REQUEST,
  LIST_PORTFOLIO_REQUEST,
  OPEN_PORTFOLIO_MODAL,
  PortfolioInfo,
  PORTFOLIO_RESET,
  UPDATE_PORTFOLIO_REQUEST,
} from './types';

export const getPortfolioList = ({
  maintag,
  subtag,
  name,
  nowPage,
}: {
  maintag: string;
  subtag: Array<string>;
  name: string;
  nowPage: number;
}) => ({
  type: LIST_PORTFOLIO_REQUEST,
  maintag,
  subtag,
  name,
  nowPage,
});

export const getPortfolioDetail = ({ _id }: { _id: string }) => ({
  type: DETAIL_PORTFOLIO_REQUEST,
  _id,
});

export const insertPortfolio = ({
  portfolio,
  addImg,
  thumbnail,
}: {
  portfolio: PortfolioInfo;
  addImg: Array<File>;
  thumbnail: string;
}) => ({
  type: INSERT_PORTFOLIO_REQUEST,
  portfolio,
  addImg,
  thumbnail,
});

export const updatePortfolio = ({
  portfolio,
  addImg,
  thumbnail,
}: {
  portfolio: PortfolioInfo;
  addImg: Array<File>;
  thumbnail: string;
}) => ({
  type: UPDATE_PORTFOLIO_REQUEST,
  portfolio,
  addImg,
  thumbnail,
});

export const deletePortfolio = ({ _id }: { _id: string }) => ({
  type: DELETE_PORTFOLIO_REQUEST,
  _id,
});

export const openPortfolioModal = ({ mode }: { mode: string }) => ({
  type: OPEN_PORTFOLIO_MODAL,
  mode,
});

export const closePortfolioModal = () => ({
  type: CLOSE_PORTFOLIO_MODAL,
});

export const getBusinessPortfolioList = ({ maintag }: { maintag: string }) => ({
  type: LIST_BUSINESS_PORTFOLIO_REQUEST,
  maintag,
});

export const resetPortfolio = () => ({
  type: PORTFOLIO_RESET,
});
