import client from 'lib/client';

export function getPopupList({ target, nowPage }: { target?: string; nowPage: number }) {
  const clientRes = client.get('/popup', { params: { target, nowPage } });
  return clientRes;
}

export function insertPopup({
  addImg,
  url,
  expire,
}: {
  addImg: File;
  url: string;
  expire: Date | null;
}) {
  let formData = new FormData();
  formData.append('addImg', addImg);
  formData.append('url', url);
  expire && formData.append('expire', expire.toString());

  const clientRes = client.post('/popup', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return clientRes;
}

export function updatePopup({
  addImg,
  img,
  url,
  expire,
  _id,
}: {
  addImg?: File;
  img: string;
  url: string;
  expire: Date | null;
  _id: string;
}) {
  let formData = new FormData();
  formData.append('url', url);
  formData.append('img', img);
  formData.append('_id', _id);
  addImg && formData.append('addImg', addImg);
  expire && formData.append('expire', expire.toString());
  const clientRes = client.put('/popup', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return clientRes;
}

export function deletePopup({ _id }: { _id: string }) {
  const clientRes = client.delete('/popup', { params: { _id } });
  return clientRes;
}
