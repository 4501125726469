import useOnScreen from 'lib/useOnScreen';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

interface VirtualStudioContent1Props {
  img: string;
  title: Array<string>;
  subTitle: Array<string>;
}
function VirtualStudioContent1({ img, title, subTitle }: VirtualStudioContent1Props) {
  const divRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const subTitleRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const onScreen = useOnScreen(divRef, '0px', 0.3);
  const [animation, setAnimation] = useState<any>();

  useEffect(() => {
    setAnimation(
      gsap
        .timeline()
        .to(imgRef.current, { y: -20, autoAlpha: 1 })
        .to(titleRef.current, { y: -20, autoAlpha: 1 })
        .to(subTitleRef.current, { y: -20, autoAlpha: 1 }, '<')
        .pause()
    );
  }, [imgRef.current, titleRef.current, subTitleRef.current]);
  useEffect(() => {
    animation && animation.play();
  }, [onScreen]);

  return (
    <div className="imgContent" ref={divRef}>
      <img src={img} alt="vstudio" ref={imgRef} />
      <div className="title" ref={titleRef}>
        {title.map((data) => (
          <Fragment key={data}>
            {data}
            <br />
          </Fragment>
        ))}
      </div>
      <div className="subTitle" ref={subTitleRef}>
        {subTitle.map((data) => (
          <Fragment key={data}>
            {data}
            <br />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default VirtualStudioContent1;
