import BusinessHeader from 'components/business/BusinessHeader';
import BusinessSubMenu from 'components/business/BusinessSubMenu';
import FMLabPage from 'components/business/FMLabPage';
import useOnScreen from 'lib/useOnScreen';
import { fmLabInfo, imagineLabInfo, techLabInfo } from 'lib/values/businessInfo';
import React, { useEffect, useRef, useState } from 'react';
import { Element } from 'react-scroll';

const fmLabSubMenu = [
  { target: 'high_imagine', title: 'Metaverse Contents Lab' },
  { target: 'untact_tech', title: '4XTL.TECH Lab' },
];

function FMLab() {
  const [focus, setFocus] = useState('high_imagine');
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);

  const onRef1Screen = useOnScreen(div1Ref, '0px', 0.3);
  const onRef2Screen = useOnScreen(div2Ref, '0px', 0.3);

  useEffect(() => {
    if (onRef2Screen) {
      setFocus('untact_tech');
    } else {
      setFocus('high_imagine');
    }
  }, [onRef2Screen, onRef1Screen]);

  return (
    <div className="fmLabWrapper noscolling">
      <BusinessHeader
        target="fmLab"
        headerTitle={fmLabInfo.headerTitle}
        subTitle={fmLabInfo.subTitle}
        contents={fmLabInfo.contents}
      />
      <div className="contentSection">
        <div className="switchingPage">
          <BusinessSubMenu menuList={fmLabSubMenu} focus={focus} />
          <div ref={div1Ref}>
            <Element name="high_imagine">
              <FMLabPage
                title={imagineLabInfo.title}
                content={imagineLabInfo.content}
                functionList={imagineLabInfo.functionList}
                linkList={imagineLabInfo.linkList}
              />
            </Element>
          </div>
          <div ref={div2Ref}>
            <Element name="untact_tech">
              <FMLabPage
                title={techLabInfo.title}
                content={techLabInfo.content}
                functionList={techLabInfo.functionList}
                linkList={techLabInfo.linkList}
              />
            </Element>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FMLab;
