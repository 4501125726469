import client from 'lib/client';
import { Portfolio, PortfolioInfo } from 'store/portfolio/types';

export function listPortfolio({
  maintag,
  subtag,
  name,
  nowPage,
}: {
  maintag: string;
  subtag?: Array<string>;
  name: string;
  nowPage: number;
}) {
  const clientRes = client.get('/portfolio/list', {
    params: { maintag, subtag: subtag ? subtag.join(',') : '', name, nowPage },
  });
  return clientRes;
}

export function detailPortfolio({ _id }: { _id: string }) {
  const clientRes = client.get('/portfolio', { params: { _id } });
  return clientRes;
}

export function insertPortfolio({
  portfolio,
  addImg,
  thumbnail,
}: {
  portfolio: any;
  addImg: Array<File>;
  thumbnail: string;
}) {
  let docForm = new FormData();

  for (let key in portfolio) {
    docForm.append(key, portfolio[key]);
  }

  addImg.forEach((img) => {
    docForm.append('addImg', img);
  });
  docForm.append('thumbnail', thumbnail);

  const clientRes = client.post('/portfolio', docForm, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return clientRes;
}

export function updatePortfolio({
  portfolio,
  addImg,
  thumbnail,
}: {
  portfolio: any;
  addImg: Array<File>;
  thumbnail: string;
}) {
  const { img } = portfolio;
  let docForm = new FormData();

  for (let key in portfolio) {
    switch (key) {
      case 'img':
        img.forEach((data: any) => docForm.append('img', JSON.stringify(data)));
        break;
      default:
        docForm.append(key, portfolio[key]);
    }
  }

  addImg.forEach((img) => {
    docForm.append('addImg', img);
  });
  docForm.append('thumbnail', thumbnail);
  const clientRes = client.put('/portfolio', docForm, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return clientRes;
}

export function deletePortfolio({ _id }: { _id: string }) {
  const clientRes = client.delete('/portfolio', { params: { _id } });
  return clientRes;
}

export function getBusinessPorfolioList({ maintag }: { maintag: string }) {
  const clientRes = client.get('/business', { params: { maintag } });
  return clientRes;
}
