import useCommon from 'hooks/common/useCommon';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import MainMenu from './MainMenu';

interface HeaderProps {
  pathName: string;
}

function Header({ pathName }: HeaderProps) {
  const setMenuName = (path: string) => {
    switch (path) {
      case 'itsolution':
        return 'it solution';
      case 'fmlab':
        return 'fm lab';
      case 'hybridevent':
        return 'hybrid event';
      case 'onlinelivesystem':
        return 'b.block';
      case 'virtualstudio':
        return 'v.studio';
      case 'originalip':
        return 'hi island';
      default:
        return path;
    }
  };

  const [open, setOpen] = useState(false);
  const { headerColor, onSetHeaderColor } = useCommon();
  const menuName = setMenuName(pathName.substring(pathName.lastIndexOf('/') + 1));
  const [display, setDisplay] = useState(pathName.indexOf('business') >= 0 ? 'hidden' : 'visible');
  const [originalColor, setOriginalColor] = useState(headerColor);

  const onHandleMenu = () => {
    setOpen(!open);
    if (
      pathName === '/' ||
      pathName === '/about' ||
      pathName === '/portfolio' ||
      pathName === '/business/itsolution' ||
      pathName === '/business/design' ||
      pathName === '/business/fmlab' ||
      pathName === '/contact'
    ) {
      if (open) {
        if (pathName === '/business/design') {
          onSetHeaderColor({ headerColor: '#ff4929' });
        } else {
          if (pathName === '/' && (originalColor === '#fff' || originalColor === 'white')) {
            onSetHeaderColor({ headerColor: '#fff' });
          } else {
            onSetHeaderColor({ headerColor: '#000' });
          }
        }
      } else {
        setOriginalColor(headerColor);
        onSetHeaderColor({ headerColor: '#fff' });
      }
    }
  };

  const onChangeScroll = () => {
    if (window.pageYOffset >= window.innerHeight * 0.7) {
      setDisplay('visible');
    } else {
      setDisplay('hidden');
    }
  };

  useEffect(() => {
    if (pathName.indexOf('business') >= 0) {
      window.addEventListener('scroll', onChangeScroll);
    }

    return () => {
      window.removeEventListener('scroll', onChangeScroll);
    };
  }, []);

  return (
    <>
      <header>
        <a href="/">
          <div className="logo">
            <svg
              id="fmLogo"
              xmlns="http://www.w3.org/2000/svg"
              width="62"
              height="26"
              viewBox="0 0 62 26"
            >
              <path
                fill={headerColor}
                id="path_F"
                data-name="path_F"
                d="M86.9,55.28l-4.254-9.771-2.031,4.824a2.6,2.6,0,0,1-2.388,1.574H74.24l2.24-5.2h0L80.3,37.92a2.6,2.6,0,0,1,2.378-1.574h.011a2.6,2.6,0,0,1,2.38,1.555l4.207,9.62,4.194-9.616a2.72,2.72,0,0,1,2.443-1.545,2.756,2.756,0,0,1,1.7.713,3.352,3.352,0,0,1,.884,1.225l3.607,8.4,7.639.007a2.6,2.6,0,1,1-.049,5.2h-9.246a2.586,2.586,0,0,1-2.347-1.5l-2.15-4.985-4.293,9.858A2.59,2.59,0,0,1,86.9,55.28Z"
                transform="translate(-50.32 -36.333)"
              />
              <path
                fill={headerColor}
                id="path_M"
                data-name="path_M"
                d="M67.51,46.753h0v0l2.242-5.2h9.5a2.608,2.608,0,0,0,0-5.216H68.1a2.7,2.7,0,0,0-2.45,1.592l-3.8,8.823v0H56.32a2.608,2.608,0,0,0,0,5.216H59.6L56.7,58.7a2.611,2.611,0,0,0,1.368,3.423,2.593,2.593,0,0,0,3.411-1.373l3.786-8.784H76.566l2.24-5.216Z"
                transform="translate(-53.722 -36.335)"
              />
            </svg>
          </div>
        </a>
        <TitleColor color={headerColor} display={display}>
          {menuName && menuName !== 'people' && menuName.toUpperCase()}
        </TitleColor>
        <button
          className={`hamburger hamburger--vortex js-hamburger ${open ? 'is-active' : ''}`}
          type="button"
          onClick={onHandleMenu}
        >
          <span className="hamburger-box">
            <Hamburger className="hamburger-inner" color={headerColor}></Hamburger>
          </span>
        </button>
      </header>
      {open && <MainMenu />}
    </>
  );
}

interface HamburgerProps {
  color: string;
}

const Hamburger = styled.span<HamburgerProps>`
  background-color: ${({ color }) => color};

  &::before,
  &::after {
    background-color: ${({ color }) => color};
  }
`;

interface TitleColorProps {
  color: string;
  display: string;
}
const TitleColor = styled.div<TitleColorProps>`
  color: ${({ color }) => color};
  visibility: ${({ display }) => display};
  font-size: 14px;
  font-weight: 900;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`;

export default Header;
