import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from 'store';
import {
  closePopupModal,
  deletePopup,
  getPopupList,
  insertPopup,
  openPopupModal,
  updatePopup,
} from 'store/popup/actions';

export default function usePopup() {
  const { popupModal, popupList, totalCount, nowPage } = useSelector(
    (state: RootState) => state.popup
  );
  const dispatch = useDispatch();

  const onOpenPopupModal = useCallback(() => dispatch(openPopupModal()), [dispatch]);
  const onClosePopupModal = useCallback(() => dispatch(closePopupModal()), [dispatch]);

  const onGetPopupList = useCallback(
    ({ nowPage, target }: { nowPage: number; target?: string }) =>
      dispatch(getPopupList({ nowPage, target })),
    [dispatch]
  );

  const onInsertPopup = useCallback(
    ({ addImg, url, expire }: { addImg: File; url: string; expire: Date | null }) =>
      dispatch(insertPopup({ addImg, url, expire })),
    [dispatch]
  );

  const onUpdatePopup = useCallback(
    ({
      addImg,
      url,
      expire,
      img,
      _id,
    }: {
      addImg?: File;
      url: string;
      expire: Date | null;
      img: string;
      _id: string;
    }) => dispatch(updatePopup({ addImg, url, expire, img, _id })),
    [dispatch]
  );

  const onDeletePopup = useCallback(({ _id }: { _id: string }) => dispatch(deletePopup({ _id })), [
    dispatch,
  ]);

  return {
    popupModal,
    popupList,
    totalCount,
    nowPage,
    onOpenPopupModal,
    onClosePopupModal,
    onGetPopupList,
    onInsertPopup,
    onUpdatePopup,
    onDeletePopup,
  };
}
