import useOnScreen from 'lib/useOnScreen';
import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { push } from 'lib/historyUtils';

interface BusinessTextImageProps {
  type: string;
  idx: number;
  first: Array<string>;
  second: { title: Array<string>; subTitle: Array<string>; description: Array<string> };
  image?: string;
  target: string;
}

function BusinessTextImage({ type, idx, first, second, image, target }: BusinessTextImageProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(divRef, '0px', 0.6);
  const [animation, setAnimation] = useState<any>();

  useEffect(() => {
    setAnimation(
      gsap
        .timeline({ paused: true })
        .to(imageRef.current, { y: -20, autoAlpha: 1 })
        .to(contentRef.current, { y: -20, autoAlpha: 1 })
    );
  }, [imageRef.current]);

  useEffect(() => {
    if (onScreen) {
      animation && animation.play();
    }
  }, [onScreen]);

  const moveToPortfolio = () => {
    push('/portfolio?tag1=' + target);
  };

  return (
    <div className={'content content_' + idx} ref={divRef}>
      {type === 'textAndImage' &&
        first.map((data) => (
          <div className="imgWrapper" key={data} ref={imageRef}>
            <img src={data} alt="b bolck" />
            {image && (
              <a onClick={moveToPortfolio}>
                PORTFOLIO
                <img src="/img/link.svg" alt="icon" />
              </a>
            )}
          </div>
        ))}
      <div className="description" ref={contentRef}>
        {second.title.length > 0 && (
          <div className="title">
            {second.title.map((data, idx) => (
              <React.Fragment key={data + idx}>
                {data}
                <br />
              </React.Fragment>
            ))}
          </div>
        )}
        {second.subTitle.length > 0 && (
          <div className="subTitle">
            {second.subTitle.map((data, idx) => (
              <React.Fragment key={data + idx}>
                {data}
                <br />
              </React.Fragment>
            ))}
          </div>
        )}
        {second.description.map((data, idx) => (
          <React.Fragment key={data + idx}>
            {data}
            <br />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default BusinessTextImage;
