import { Dialog } from '@material-ui/core';
import Loading from 'components/common/Loading';
import useCommon from 'hooks/common/useCommon';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import React from 'react';
import AddPortfolio from './AddPortfolio';
import DetailPortfolio from './DetailPortfolio';

function PortfolioModal() {
  const { loading } = useCommon();
  const { portfolioModal, portfolioMode, onClosePortfolioModal } = usePortfolio();

  return (
    <Dialog
      className="singlePortfolio"
      open={portfolioModal}
      onClose={onClosePortfolioModal}
      disableBackdropClick
      disableEscapeKeyDown
    >
      {loading && <Loading />}
      {(portfolioMode === 'add' || portfolioMode === 'edit') && (
        <AddPortfolio mode={portfolioMode} />
      )}
      {portfolioMode === 'view' && <DetailPortfolio />}
    </Dialog>
  );
}

export default PortfolioModal;
