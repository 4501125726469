import React from 'react';
import { Pagination } from '@material-ui/lab';

interface PaginationProps {
  totalCount: number; // 총 개수
  countPerPage: number; // 한 페이지당 개수
  setPage: (value: number) => void;
  nowPage: number; //현재 페이지 (1페이지면 0 으로 되어있음, index와 비슷)
}

function Paging({ totalCount, countPerPage, setPage, nowPage }: PaginationProps) {
  const onChange = (e: React.ChangeEvent<unknown>, value: number) => {
    setPage(value < 0 ? 0 : value - 1); // 실제 클릭한 페이지 value
  };

  return (
    <Pagination
      count={
        !totalCount
          ? 1
          : totalCount % countPerPage === 0
          ? totalCount / countPerPage
          : Math.trunc(totalCount / countPerPage) + 1
      }
      onChange={onChange}
      page={nowPage + 1}
    />
  );
}

export default React.memo(Paging);
