import React from 'react';
import MainPage from 'pages/MainPage';
import Portfolio from 'pages/Portfolio';
import Login from 'pages/Login';
import MainLayout from 'pages/MainLayout';
import About from 'pages/About';
import Business from 'pages/Business';
import People from 'pages/People';
import Contact from 'pages/Contact';
import HybridEvent from 'pages/business/HybridEvent';
import OnlineLiveSystem from 'pages/business/OnlineLiveSystem';
import VirtualStudio from 'pages/business/VirtualStudio';
import Design from 'pages/business/Design';
import OriginalIP from 'pages/business/OriginalIP';
import ITSolution from 'pages/business/ITSolution';
import FMLab from 'pages/business/FMLab';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from 'pages/PageNotFound';
import AdminLayout from 'pages/AdminLayout';
import AdminPortfolio from 'pages/admin/AdminPortfolio';
import AdminPopup from 'pages/admin/AdminPopup';

function App() {
  return (
    <Switch>
      <MainLayout exact path="/" component={MainPage}></MainLayout>
      <MainLayout exact path="/portfolio" component={Portfolio}></MainLayout>
      <MainLayout exact path="/about" component={About}></MainLayout>
      <MainLayout exact path="/business" component={Business}></MainLayout>
      <MainLayout exact path="/people" component={People}></MainLayout>
      <MainLayout exact path="/contact" component={Contact}></MainLayout>
      <MainLayout exact path="/business/hybridevent" component={HybridEvent}></MainLayout>
      <MainLayout exact path="/business/onlinelivesystem" component={OnlineLiveSystem}></MainLayout>
      <MainLayout exact path="/business/virtualstudio" component={VirtualStudio}></MainLayout>
      <MainLayout exact path="/business/design" component={Design}></MainLayout>
      <MainLayout exact path="/business/originalip" component={OriginalIP}></MainLayout>
      <MainLayout exact path="/business/itsolution" component={ITSolution}></MainLayout>
      <MainLayout exact path="/business/fmlab" component={FMLab}></MainLayout>
      <Route exact path="/admin/login" component={Login}></Route>
      <AdminLayout exact path="/admin/popup" component={AdminPopup}></AdminLayout>
      <AdminLayout exact path="/admin/portfolio" component={AdminPortfolio}></AdminLayout>
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}

export default App;
