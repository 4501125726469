import usePortfolio from 'hooks/portfolio/usePortfolio';
import React, { Fragment } from 'react';
import { PortfolioInfo } from 'store/portfolio/types';

interface PortfolioTileProps {
  portfolio: PortfolioInfo;
}

function PortfolioTile({ portfolio }: PortfolioTileProps) {
  const { onGetPortfolioDetail } = usePortfolio();
  const onClick = () => {
    onGetPortfolioDetail({ _id: portfolio._id });
  };

  return (
    <div className="singlePortFolioTileWrapper">
      <div className="singlePortFolioTileImg">
        {portfolio.thumbnail && <img src={portfolio.thumbnail.src} alt="portfolio thumbanil" />}
      </div>
      <div onClick={onClick} className="singlePortFolioTileInfo">
        <div className="title">{portfolio.name}</div>
        <div className="categoryWrapper">
          {portfolio.maintag.map((data, idx) =>
            data === 'Hybrid Event' ? (
              <Fragment key={portfolio._id + data}>
                {portfolio.subtag.map((tag, idx) => (
                  <span key={portfolio._id + data + tag + idx}>
                    {data} {tag}
                  </span>
                ))}
              </Fragment>
            ) : (
              <span key={portfolio._id + data + idx}>{data}</span>
            )
          )}
        </div>
        {/* <div>{portfolio.client}</div> */}
        <div className="linkMarker">
          <img src="/img/link.svg" alt="link" />
        </div>
      </div>
    </div>
  );
}

export default PortfolioTile;
