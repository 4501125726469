import usePopup from 'hooks/popup/usePopup';
import Slider from 'react-slick';

interface PopupProps {
  onPopupClick: () => void;
  onClosePopup: () => void;
}
function Popup({ onPopupClick, onClosePopup }: PopupProps) {
  const { popupList } = usePopup();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
  };

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;

    const popup = popupList.filter((data) => data._id === id)[0];

    if (popup && popup.url) {
      window.open(popup.url, '_blank');
    }
  };

  const onButtonClick = () => {
    onPopupClick();
  };
  return (
    <div className="popupBgWrapper">
        <div className="popupWrapper">
          <Slider {...settings}>
            {popupList.map((data) => (
              <div className="imgWrapper" key={data._id} onClick={onClick} id={data._id}>
                <img src={data.img} className="slider-image" />
              </div>
            ))}
          </Slider>
          <div className="uiWrapper">
            <button onClick={onPopupClick}>오늘하루보지 않기</button>
            <button onClick={onClosePopup}>Close</button>
          </div>
        </div>
    </div>
  );
}

export default Popup;
