import { PopupActionTypes } from './actionTypes';
import { CLOSE_POPUP_MODAL, LIST_POPUP_SUCCESS, OPEN_POPUP_MODAL, Popup } from './types';

const initialState: Popup = {
  totalCount: 0,
  nowPage: 0,
  popupList: [],
  popupModal: false,
};

const popupReducer = (state = initialState, action: PopupActionTypes) => {
  switch (action.type) {
    case OPEN_POPUP_MODAL:
      return {
        ...state,
        popupModal: true,
      };
    case CLOSE_POPUP_MODAL:
      return {
        ...state,
        popupModal: false,
      };
    case LIST_POPUP_SUCCESS:
      return {
        ...state,
        totalCount: action.totalCount,
        nowPage: action.nowPage,
        popupList: action.popupList,
        popupModal: false,
      };
    default:
      return state;
  }
};

export default popupReducer;
