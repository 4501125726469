export interface Portfolio {
  portfolioModal: boolean;
  portfolioMode: string;
  portfolioList: Array<PortfolioInfo>;
  nowPage: number;
  totalCount: number;
  portfolio: PortfolioInfo;
  maintag: string;
  subtag: Array<string>;
  name: string;
  businessPortfolioList: Array<PortfolioInfo>;
}

export interface PortfolioInfo {
  _id: string;
  name: string; // project name
  img: Array<Attachment>;
  thumbnail: Attachment;
  note: string; ///설명
  maintag: Array<string>;
  subtag: Array<string>;
  client: string;
  venue: string;
  orglist: Array<string>;
  BM: Array<string>;
  start: Date | null;
  end: Date | null;
  project: string;
  url: string;
}

export interface Attachment {
  src: string;
  title: string;
}

export const LIST_PORTFOLIO_REQUEST = 'LIST_PORTFOLIO_REQUEST';
export const LIST_PORTFOLIO_SUCCESS = 'LIST_PORTFOLIO_SUCCESS';

export const OPEN_PORTFOLIO_MODAL = 'OPEN_PORTFOLIO_MODAL';
export const CLOSE_PORTFOLIO_MODAL = 'CLOSE_PORTFOLIO_MODAL';

export const DETAIL_PORTFOLIO_REQUEST = 'DETAIL_PORTFOLIO_REQUEST';
export const DETAIL_PORTFOLIO_SUCCESS = 'DETAIL_PORTFOLIO_SUCCESS';

export const INSERT_PORTFOLIO_REQUEST = 'INSERT_PORTFOLIO_REQUEST';
export const INSERT_PORTFOLIO_SUCCESS = 'INSERT_PORTFOLIO_SUCCESS';

export const UPDATE_PORTFOLIO_REQUEST = 'UPDATE_PORTFOLIO_REQUEST';
export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS';

export const DELETE_PORTFOLIO_REQUEST = 'DELETE_PORTFOLIO_REQUEST';
export const DELETE_PORTFOLIO_SUCCESS = 'DELETE_PORTFOLIO_SUCCESS';

export const PORTFOLIO_FAILURE = 'PORTFOLIO_FAILURE';

export const LIST_BUSINESS_PORTFOLIO_REQUEST = 'LIST_BUSINESS_PORTFOLIO_REQUEST';
export const LIST_BUSINESS_PORTFOLIO_SUCCESS = 'LIST_BUSINESS_PORTFOLIO_SUCCESS';

export const PORTFOLIO_RESET = 'PORTFOLIO_RESET';
