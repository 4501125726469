import React, { useState } from 'react';
import useInput from 'lib/useInput';
import styled from 'styled-components';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DatePicker from 'components/common/DatePicker';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import { setDateFormat } from 'lib/setDateFormat';
import { portfolioMainTag, portfolioSubTag } from 'lib/values/portfolioValues';
import { PortfolioInfo } from 'store/portfolio/types';

interface AddPortfolioProps {
  mode: string;
}

function AddPortfolio({ mode }: AddPortfolioProps) {
  const {
    onInsertPortfolio,
    onUpdatePortfolio,
    onDeletePortfolio,
    onClosePortfolioModal,
    portfolio,
  } = usePortfolio();
  const [dateModal, setDateModal] = useState(false);
  const [dateTarget, setDateTarget] = useState('');
  const [name, setName] = useInput(mode === 'add' ? '' : portfolio.name);
  const [client, setClient] = useInput(mode === 'add' ? '' : portfolio.client);
  const [start, setStart] = useState<Date | null>(mode === 'add' ? null : portfolio.start);
  const [end, setEnd] = useState<Date | null>(mode === 'add' ? null : portfolio.end);
  const [venue, setVenue] = useInput(mode === 'add' ? '' : portfolio.venue);
  const [note, setNote] = useState(mode === 'add' ? '' : portfolio.note);
  const [maintag, setMaintag] = useState(mode === 'add' ? [] : portfolio.maintag);
  const [subtag, setSubtag] = useState(mode === 'add' ? [] : portfolio.subtag);
  const [img, setImg] = useState(mode === 'add' ? [] : portfolio.img);
  const [addImg, setAddImg] = useState<Array<File>>([]);
  const [orglist, setOrglist] = useState(mode === 'add' ? [''] : portfolio.orglist);
  const [BM, setBM] = useState(mode === 'add' ? [''] : portfolio.BM);
  const [thumbnail, setThumbnail] = useState(mode === 'add' ? '' : portfolio.thumbnail.title);
  const [project, setProject] = useInput(mode === 'add' ? '' : portfolio.project);
  const [url, setUrl] = useInput(mode === 'add' ? '' : portfolio.url);

  const onDelete = () => {
    onDeletePortfolio({ _id: portfolio._id });
  };

  const onClick = () => {
    if (!name.trim()) {
      alert('프로젝트명을 입력해주세요.');
      return;
    }

    if (!client.trim()) {
      alert('클라이언트명을 입력해주세요.');
      return;
    }

    if (!venue.trim()) {
      alert('장소를 입력해주세요.');
      return;
    }

    if (!note.trim()) {
      alert('설명을 입력해주세요.');
      return;
    }

    if (maintag.length < 1) {
      alert('메인 태그를 선택해주세요.');
      return;
    }

    if (maintag.filter((data) => data === 'Hybrid Event').length > 0 && subtag.length < 1) {
      alert('서브 태그를 선택해주세요.');
      return;
    }

    if (img.length + addImg.length < 1) {
      alert('사진을 추가해주세요.(최대 3장)');
      return;
    }

    const filteredOrgList = orglist.filter((data) => data.trim() !== '');
    const filteredBMList = BM.filter((data) => data.trim() !== '');

    if (filteredOrgList.length < 1) {
      alert('참여 부서명을 입력해주세요.');
      return;
    }

    if (filteredBMList.length < 1) {
      alert('참여 BM명을 입력해주세요.');
      return;
    }

    const portfolioInfo = {
      _id: mode === 'add' ? null : portfolio._id,
      name,
      img,
      note,
      maintag,
      subtag,
      client,
      venue,
      orglist,
      start,
      end,
      BM,
      project,
      url,
    } as PortfolioInfo;

    mode === 'add' && onInsertPortfolio({ portfolio: portfolioInfo, addImg, thumbnail });
    mode === 'edit' && onUpdatePortfolio({ portfolio: portfolioInfo, addImg, thumbnail });
  };

  const handleDateModal = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;
    id && setDateTarget(id);
    setDateModal(!dateModal);
  };

  const handleDateChange = ({ id, date }: { id: string; date: Date | null }) => {
    id === 'start' && setStart(date);
    id === 'end' && setEnd(date);
    setDateModal(false);
  };

  const onNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setNote(value);
  };

  const onOrgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, dataset } = e.target;
    const { idx } = dataset;

    if (!idx) return;

    setOrglist(
      orglist.map((org, index) => {
        if (index === Number(idx)) {
          org = value;
        }

        return org;
      })
    );
  };

  const onBMChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, dataset } = e.target;
    const { idx } = dataset;

    if (!idx) return;

    setBM(
      BM.map((user, index) => {
        if (index === Number(idx)) {
          user = value;
        }

        return user;
      })
    );
  };

  const onMaintagClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;
    const isExist = maintag.filter((data) => data === id).length > 0;

    if (isExist) {
      setMaintag(maintag.filter((data) => data !== id));
      if (id === 'Hybrid Event') {
        setSubtag([]);
      }
    } else {
      setMaintag(maintag.concat(id));
    }
  };

  const onSubtagClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;
    const isExist = subtag.filter((data) => data === id).length > 0;

    if (isExist) {
      setSubtag(subtag.filter((data) => data !== id));
    } else {
      setSubtag(subtag.concat(id));
    }
  };

  const onAddOrg = () => {
    setOrglist(orglist.concat(''));
  };

  const onAddBM = () => {
    setBM(BM.concat(''));
  };

  const deleteOrglist = (e: React.MouseEvent<HTMLElement>) => {
    const index = e.currentTarget.getAttribute('data-idx');

    if (!index) return;
    setOrglist(orglist.filter((data, idx) => idx !== Number(index)));
  };

  const deleteBMList = (e: React.MouseEvent<HTMLElement>) => {
    const index = e.currentTarget.getAttribute('data-idx');

    if (!index) return;
    setBM(BM.filter((data, idx) => idx !== Number(index)));
  };

  const onAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : null;

    if (files) {
      const imageList = addImg.concat(files);

      if (img.length + imageList.length > 5) {
        alert('파일은 5개까지 업로드가 가능합니다.');
        return;
      }

      setAddImg(imageList);
      if (!thumbnail) setThumbnail(imageList[0].name);
    }
  };

  const deleteImg = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget.getAttribute('data-target');
    if (!target) return;
    setImg(img.filter((data, idx) => idx !== Number(target)));
  };

  const deleteAddImg = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget.getAttribute('data-target');
    if (!target) return;
    setAddImg(addImg.filter((data, idx) => idx !== Number(target)));
  };

  const setFileName = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget.getAttribute('data-target');
    if (!target) return;

    setThumbnail(target);
  };
  return (
    <>
      {/* <DialogTitle>정보 {mode === 'add' ? '등록' : '수정'}</DialogTitle> */}
      <DialogContent>
        <div className="singlePortUI">
          {mode === 'add' && (
            <button onClick={onClick}>
              <img src="/img/regist.svg" alt="regist" />
            </button>
          )}
          {mode === 'edit' && (
            <>
              <button onClick={onClick}>
                <img src="/img/edit.svg" alt="edit" />
              </button>
              <button onClick={onDelete}>
                <img src="/img/delete.svg" alt="delete" />
              </button>
            </>
          )}
          <button onClick={onClosePortfolioModal}>
            <img src="/img/close.svg" alt="close" />
          </button>
        </div>
        <div className="imgsWrapper addImg">
          <div className="hiddenImgSelect">
            <input
              accept="image/*"
              name="image"
              id="contained-button-file"
              multiple={true}
              type="file"
              onChange={onAddImage}
            />
          </div>
          <label htmlFor="contained-button-file" className="addGeneralBtn">
            Upload
          </label>
          {mode === 'edit' &&
            img &&
            img.map((data, idx) => (
              <Image key={data.src} thumbnail={thumbnail} title={data.title}>
                <img src={data.src} onClick={setFileName} data-target={data.title} />
                <button onClick={deleteImg} data-target={idx}>
                  <img src="/img/close.svg" alt="delete" />
                </button>
              </Image>
            ))}
          {addImg.map((data, idx) => (
            <Image key={URL.createObjectURL(data)} thumbnail={thumbnail} title={data.name}>
              <img
                src={URL.createObjectURL(data)}
                alt="locations"
                onClick={setFileName}
                data-target={data.name}
              />
              <button onClick={deleteAddImg} data-target={idx}>
                <img src="/img/close.svg" alt="delete" />
              </button>
            </Image>
          ))}
        </div>
        <div className="contentWrapper">
          <div className="title">
            <input type="text" value={name} onChange={setName} />
          </div>
          {/* category */}
          <div className="categorySelectWrapper">
            <div className="mainCategory">
              {portfolioMainTag.map(
                (data) =>
                  data !== 'All Projects' && (
                    <MaintagDiv
                      key={data}
                      onClick={onMaintagClick}
                      id={data}
                      selected={maintag.filter((main) => main === data).length > 0}
                    >
                      {data}{' '}
                    </MaintagDiv>
                  )
              )}
            </div>
            <div className="subCategory">
              {maintag.filter((main) => main === 'Hybrid Event').length > 0 && (
                <>
                  {portfolioSubTag.map((sub) => (
                    <SubtagDiv
                      key={sub}
                      onClick={onSubtagClick}
                      id={sub}
                      selected={subtag.filter((text) => text === sub).length > 0}
                    >
                      {sub}
                    </SubtagDiv>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="client contentGeneral">
            <label htmlFor="">Client</label>
            <input type="text" value={client} onChange={setClient} />
          </div>
          <div className="period contentGeneral">
            <label htmlFor="">Date</label>
            <div className="periodSelectWrapper">
              <input
                type="text"
                value={start ? setDateFormat(start.toString(), 'yyyy-MM-dd') : ''}
                disabled={true}
              />
              <button id="start" onClick={handleDateModal}>
                <img src="/img/date.svg" alt="date" />
              </button>
              <span>~</span>
              <input
                type="text"
                value={end ? setDateFormat(end.toString(), 'yyyy-MM-dd') : ''}
                disabled={true}
              />
              <button id="end" onClick={handleDateModal}>
                <img src="/img/date.svg" alt="date" />
              </button>
            </div>
          </div>
          <div className="url contentGeneral">
            <label htmlFor="">Link</label>
            <input type="text" value={url} onChange={setUrl} />
          </div>
          <div className="location contentGeneral">
            <label htmlFor="">Place</label>
            <input type="text" value={venue} onChange={setVenue} />
          </div>
          <div className="description">
            <textarea value={note} onChange={onNoteChange} />
          </div>
          <div className="deptWrapper">
            <label htmlFor="">TEAM</label>
            <button className="addTeamAndMeber" onClick={onAddOrg}>
              <img src="/img/add.svg" alt="add team"/>
            </button>
            <div className="teamList">
            {orglist.map((org, idx) => (
              <div className="inputTeamAndMeber" key={idx}>
                <input type="text" data-idx={idx} value={org} onChange={onOrgChange} />
                <button onClick={deleteOrglist} data-idx={idx}>
                  <img src="/img/close.svg" alt="remove"/>
                </button>
              </div>
            ))}
            </div>
          </div>
          <div className="memberWrapper">
            <label htmlFor="">Member</label>
            <button onClick={onAddBM}>
              <img src="/img/add.svg" alt="add member"/>
            </button>
            <div className="memberList">
            {BM.map((user, idx) => (
              <div className="inputTeamAndMeber" key={idx}>
                <input type="text" data-idx={idx} value={user} onChange={onBMChange} />
                <button onClick={deleteBMList} data-idx={idx}>
                <img src="/img/close.svg" alt="remove"/>
                </button>
              </div>
            ))}
            </div>
          </div>
          <div className="projectID contentGeneral">
            <label htmlFor="">Project ID</label>
            <input type="text" value={project} onChange={setProject} />
          </div>
        </div>
      </DialogContent>
      {dateModal && (
        <DatePicker
          value={dateTarget === 'start' ? start : end}
          onChange={handleDateChange}
          id={dateTarget}
          open={dateModal}
          onClose={handleDateModal}
        />
      )}
    </>
  );
}

interface TagProps {
  selected: boolean;
}

const MaintagDiv = styled.div<TagProps>`
  background-color: ${({ selected }) => (selected ? `#d1aad3` : `transparent`)};
`;

const SubtagDiv = styled.div<TagProps>`
  background-color: ${({ selected }) => (selected ? `#d1aad3` : `transparent`)};
`;

interface ImageProps {
  thumbnail: string;
  title: string;
}

const Image = styled.div<ImageProps>`
  border: ${({ thumbnail, title }) => (thumbnail === title ? `2px solid orange` : `none`)};
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  button {
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 4px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    right: 0;
  }
`;

export default AddPortfolio;
