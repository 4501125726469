import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import userReducer from './user/reducers';
import commonReducer from './common/reducers';
import { Common } from './common/types';
import { User } from './user/types';
import commonSaga from './common/sagas';
import userSaga from './user/saga';
import portfolioReducer from './portfolio/reducers';
import { Portfolio } from './portfolio/types';
import portfolioSaga from './portfolio/sagas';
import popupReducer from './popup/reducers';
import { Popup } from './popup/types';
import popupSaga from './popup/sagas';

const rootReducer = combineReducers({
  common: commonReducer,
  user: userReducer,
  portfolio: portfolioReducer,
  popup: popupReducer,
});

export default rootReducer;

export type RootState = {
  common: Common;
  user: User;
  portfolio: Portfolio;
  popup: Popup;
};

export function* rootSaga() {
  yield all([commonSaga(), userSaga(), portfolioSaga(), popupSaga()]);
}
