import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'css/main.scss';
import Slider from 'react-slick';
import usePortfolio from 'hooks/portfolio/usePortfolio';
import { setDateFormat } from 'lib/setDateFormat';
import React from 'react';

function DetailPortfolio() {
  const storageUser = localStorage.getItem('portfolio');
  const { portfolio, onClosePortfolioModal, onOpenPortfolioModal } = usePortfolio();

  const onOpenEditModal = () => {
    onOpenPortfolioModal({ mode: 'edit' });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <DialogContent>
        <div className="singlePortUI">
          {storageUser === 'yes' && (
            <button onClick={onOpenEditModal}>
              <img src="/img/edit.svg" alt="edit" />
            </button>
          )}
          <button onClick={onClosePortfolioModal}>
            <img className="mobile" src="/img/close_w.svg" alt="close" />
            <img className="notmobile" src="/img/close.svg" alt="close" />
          </button>
        </div>
        <div className="imgsWrapper">
          <Slider {...settings}>
            {portfolio.img.map((data) => (
              <div className="imgWrapper" key={data.src}>
                <img src={data.src} className="slider-image" />
              </div>
            ))}
          </Slider>
        </div>
        <div className="contentWrapper">
          <div className="title">{portfolio.name}</div>
          <div className="categorySelectWrapper">
            {' '}
            {portfolio.maintag.map((tag) => (
              <>
                {tag !== 'hybrid event' ? (
                  <div className="singleCategory" key={tag}>
                    {tag}
                  </div>
                ) : (
                  portfolio.subtag.map((data) => (
                    <div className="singleCategory" key={tag + data}>
                      {tag}:{data}
                    </div>
                  ))
                )}
              </>
            ))}
          </div>
          <div className="client contentGeneral">
            <label htmlFor="">Client</label>
            <div className="generalContent">{portfolio.client}</div>
          </div>
          <div className="period contentGeneral">
            <label htmlFor="">Date</label>
            <div className="generalContent">
              {portfolio.start && setDateFormat(portfolio.start.toString(), 'yyyy-MM-dd')}
              {portfolio.end && ' ~ '}
              {portfolio.end && setDateFormat(portfolio.end.toString(), 'yyyy-MM-dd')}
            </div>
          </div>
          <div className="url contentGeneral">
            <label htmlFor="">Link</label>
            <div className="generalContent">
              {portfolio.url ? (
                <a href={portfolio.url} target="_blank">
                  {portfolio.url}
                </a>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="location contentGeneral">
            <label htmlFor="">Place</label>
            <div className="generalContent">{portfolio.venue}</div>
          </div>
          <div className="description viewOnly">{portfolio.note}</div>
          <div className="deptWrapper viewOnly">
            <label htmlFor="">TEAM</label>
            <div className="dept">
              {portfolio.orglist.map((data) => (
                <span>{data}</span>
              ))}
            </div>
            <div className="member">
              {portfolio.BM.map((data) => (
                <span>{data} BM</span>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );
}

export default DetailPortfolio;
