import BusinessArrow from 'components/common/BusinessArrow';
import Diagram from 'components/common/Diagram';
import gsap from 'gsap';
import useCommon from 'hooks/common/useCommon';
import useOnScreen from 'lib/useOnScreen';
import { peopleDiagram } from 'lib/values/businessInfo';
import React, { createRef, useEffect, useRef } from 'react';

function People() {
  const { onSetHeaderColor } = useCommon();
  const headerRef = useRef<HTMLDivElement>(null);
  const subRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const whatWeDoRef = useRef<HTMLDivElement>(null);
  let jobRef = useRef<Array<HTMLDivElement | null>>([]);
  const sectionRef = useRef<HTMLDivElement>(null);

  const ourWelfareRef = useRef<HTMLDivElement>(null);
  let welfareRef = useRef<Array<HTMLDivElement | null>>([]);

  const onScreenWhatWeDo = useOnScreen(whatWeDoRef);
  const onScreenOurWelfare = useOnScreen(ourWelfareRef);
  const onScreenContent = useOnScreen(sectionRef, '0px', 0.1);

  useEffect(() => {
    if (headerRef.current && subRef.current && contentRef.current) {
      gsap
        .timeline()
        .to(headerRef.current, { y: -20, autoAlpha: 1 })
        .to(subRef.current, { y: -20, autoAlpha: 1 })
        .to(contentRef.current, { y: -20, autoAlpha: 1 });
    }
  }, []);

  useEffect(() => {
    if (onScreenContent) {
      onSetHeaderColor({ headerColor: '#fff' });
    }
  }, [onScreenContent]);

  useEffect(() => {
    if (onScreenWhatWeDo) {
      onSetHeaderColor({ headerColor: '#000' });
      gsap
        .timeline()
        .to(whatWeDoRef.current, { y: -20, autoAlpha: 1 })
        .to(jobRef.current, { y: -20, autoAlpha: 1, stagger: 0.2 });
    }
  }, [onScreenWhatWeDo]);

  useEffect(() => {
    if (onScreenOurWelfare) {
      gsap
        .timeline()
        .to(ourWelfareRef.current, { y: -20, autoAlpha: 1 })
        .to(welfareRef.current, { y: -20, autoAlpha: 1, stagger: 0.2 });
    }
  }, [onScreenOurWelfare]);

  return (
    <div className="peopleWrapper">
      <div className="peopleHeadSectionWrapper">
        <div className="headSection">
          <div className="headTitle" ref={headerRef}>
            BE MOVED <br />
            MAKERS
          </div>
          <div className="subTitle small" ref={subRef}>
            새로움, 신기함, 놀라움, 즐거움, 행복감 <br />
            상상을 통해 마음의 울림을 만드는
          </div>
        </div>
        <BusinessArrow path="people" />
      </div>
      <div className="peopleSecondSectionWrapper">
        <div className="secondSection" ref={sectionRef}>
          <div className="titleOut" ref={contentRef}>
            우리는 <br />
            감동메이커 입니다.
          </div>
          <Diagram
            title={peopleDiagram.title}
            subtitle={peopleDiagram.subtitle}
            description={peopleDiagram.description}
            diagram={peopleDiagram.diagram}
          />
        </div>
      </div>
      <div className="peopleThirdSectionWrapper">
        <div className="thirdSection">
          <div className="whatWeDo">
            <div className="title headerTitle" ref={whatWeDoRef}>
              What <br />
              We Do
            </div>
            <div className="jobWrapper">
              <div className="job" ref={(element) => (jobRef.current[0] = element)}>
                <div className="title">
                  Marketing & <br />
                  Management
                </div>
                <div className="imgWrapper">
                  <img src="/img/m_m.jpg" alt="marketing" />
                </div>
                <div className="description">
                  프로모션, 소셜 마케팅 등 <br />
                  브랜드 경험을 극대화 하는 다양한 마케팅 <br />
                  활동을 기획하며 운영 전반을 <br />
                  케어합니다.
                </div>
              </div>
              <div className="job" ref={(element) => (jobRef.current[1] = element)}>
                <div className="title">
                  Contents <br />
                  Creation
                </div>
                <div className="imgWrapper">
                  <img src="/img/c_c.jpg" alt="marketing" />
                </div>
                <div className="description">
                  새로운 콘텐츠 창조를 위한 콘셉트와 <br />
                  스토리를 기획하며 콘텐츠 IP를 <br />
                  바탕으로 대중과의 브랜드 <br />
                  커뮤니케이션을 진행합니다.
                </div>
              </div>
              <div className="job" ref={(element) => (jobRef.current[2] = element)}>
                <div className="title">
                  Design <br />
                  <br />
                </div>
                <div className="imgWrapper">
                  <img src="/img/d.jpg" alt="marketing" />
                </div>
                <div className="description">
                  브랜드를 잘 이해하고, 적합한 콘셉트의 <br />
                  디자인을 기획합니다. 프로젝트의 <br />
                  특성에 따라 2D, 3D, 일러스트, 그래픽 등의 <br />
                  각기 다른 디자인툴을 자유롭게 활용합니다.
                </div>
              </div>
              <div className="job" ref={(element) => (jobRef.current[3] = element)}>
                <div className="title">
                  Web <br />
                  Development
                </div>
                <div className="imgWrapper">
                  <img src="/img/dev.jpg" alt="marketing" />
                </div>
                <div className="description">
                  React, Node.JS, Web GL에 대한 <br />
                  이해와 숙련도를 바탕으로 웹 환경의 <br />
                  새로운 브랜드 경험을 위한 기술을 <br />
                  개발합니다.
                </div>
              </div>
              <div className="job" ref={(element) => (jobRef.current[4] = element)}>
                <div className="title">
                  XR <br />
                  (Extended Reality)
                  <br />
                </div>
                <div className="imgWrapper">
                  <img src="/img/3d.jpg" alt="marketing" />
                </div>
                <div className="description">
                  언리얼 엔진과 Unity를 활용하여 <br />
                  생생한 환경에서 사용자에게 <br />
                  사실적인 경험을 제공하는 실감형 콘텐츠를 <br />
                  제작합니다.
                </div>
              </div>
            </div>
          </div>
          <div className="ourWelfare">
            <div ref={ourWelfareRef} className="ourWelfareWrapper">
              <div className="title">
                Our <br />
                Welfare
              </div>
              <div className="description">
                에프엠 커뮤니케이션즈는 더 많은 감동을 <br />
                만들 수 있도록 다양한 환경을 제공합니다.
              </div>
            </div>
            <div className="welfareWrapper">
              <div className="welfare" ref={(element) => (welfareRef.current[0] = element)}>
                <div className="title">더 알차게</div>
                <div className="description">
                  -&emsp;연 1회 종합 건강검진 <br />
                  -&emsp;육아 근로 시간 단축 및 휴직 <br />
                  -&emsp;장기 근속 포상
                </div>
              </div>
              <div className="welfare" ref={(element) => (welfareRef.current[1] = element)}>
                <div className="title">더 즐겁게</div>
                <div className="description">
                  -&emsp;Refresh 유급 휴직 <br />
                  -&emsp;자기 개발비 지원 <br />
                  -&emsp;해외 프로그램 관람 지원
                </div>
              </div>
              <div className="welfare" ref={(element) => (welfareRef.current[2] = element)}>
                <div className="title">더 편하게</div>
                <div className="description">
                  -&emsp;사내 스낵바 운영 <br />
                  -&emsp;사내 휴게실 운영 <br />
                  -&emsp;개인형 법인 카드 1인 1카드 지급
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="peopleFifthSectionWrapper">
        <div className="imgWrapper footerImgSmall">
          <img src="/img/people_footer_1.jpg" alt="people footer" />
        </div>
        <div className="imgWrapper footerImgSmall">
          <img src="/img/people_footer_2.jpg" alt="people footer" />
        </div>
        <div className="imgWrapper footerImglarge">
          <img src="/img/people_footer_3.jpg" alt="people footer" />
        </div>
      </div> */}
    </div>
  );
}

export default People;
