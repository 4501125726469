import Loading from 'components/common/Loading';
import useCommon from 'hooks/common/useCommon';
import React from 'react';
import { Link, Route, useLocation } from 'react-router-dom';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}
function AdminLayout({ component: Component, ...rest }: IProps) {
  const location = useLocation();
  const { loading } = useCommon();

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div key={location.key} className="pageTransition">
          {loading && <Loading />}
            <div className="adminNAv">
              <Link to="/admin/portfolio">Portfolio</Link>
              <Link to="/admin/popup">Popup</Link>
            </div>
            <Component {...matchProps} />
        </div>
      )}
    />
  );
}

export default AdminLayout;
