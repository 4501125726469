import usePortfolio from 'hooks/portfolio/usePortfolio';
import { portfolioMainTag } from 'lib/values/portfolioValues';
import styled from 'styled-components';
import React from 'react';

interface SubMenuProps {
  onHandleSubMenu: () => void;
}

function SubMenu({ onHandleSubMenu }: SubMenuProps) {
  const { maintag, subtag, onGetPortfolioList } = usePortfolio();

  const onChangeMaintag = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;

    if (id === 'Hybrid Event') {
      onGetPortfolioList({ maintag: 'Hybrid Event', subtag, name: '', nowPage: 0 });
    } else if (id === 'All Projects') {
      onGetPortfolioList({ maintag: '', subtag: [], name: '', nowPage: 0 });
    } else {
      onGetPortfolioList({ maintag: id, subtag: [], name: '', nowPage: 0 });
    }

    onHandleSubMenu();
  };

  return (
    <div className="subMenu">
      <button onClick={onHandleSubMenu}></button>
      <div>
        {portfolioMainTag.map((data, idx) => (
          <div className="portfolioBtn" key={data}>
            <div className="menuBtnStyle" key={data} id={data} onClick={onChangeMaintag}>
              <span>{idx >= 10 ? idx + 1 : '0' + idx}</span>
              {data}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubMenu;
