import React from 'react';
import ErrorIcon from 'css/img/404_error.gif';
import { push } from 'lib/historyUtils';

function PageNotFound() {
  const onClick = () => {
    push('/');
  };
  return (
    <div className="notfoundWrapper">
      <div className="bgContact">
        <img src="/img/404_bg.png" alt="bg 404" />
      </div>
      <div className="notfound">  
        <img src={ErrorIcon} alt="404notfound" />
      </div>
      <button onClick={onClick}>Home</button>
      <div className="fixedBg">
        <img src="/img/bg_black_line.png" alt="line bg" />
      </div>
    </div>
  );
}

export default PageNotFound;
