import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

interface PrivacyPoliticModalProps {
  open: boolean;
  onClose: () => void;
}

function PrivacyPoliticModal({ open, onClose }: PrivacyPoliticModalProps) {
  const title = '<에프엠커뮤니케이션즈>';
  return (
    <Dialog className="privacyPolitic" open={open} onClose={onClose}>
      <DialogContent>
        <button onClick={onClose}>
          <img className="notmobile" src="/img/close.svg" alt="close" />
        </button>
        <h4>개인정보처리방침</h4>
        <p>
          {title}('www.feelxmove.com'이하 '에프엠커뮤니케이션즈')은(는) 개인정보보호법에 따라
          이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게
          처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
        </p>
        <p>
          {title}('에프엠커뮤니케이션즈') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트
          공지사항(또는 개별공지)을 통하여 공지할 것입니다.
        </p>
        <p>○ 본 방침은부터 2020년 12월 24일부터 시행됩니다.</p>
        <div>1. 개인정보의 처리 목적 </div>
        <p>
          {title}
          ('www.feelxmove.com'이하 '에프엠커뮤니케이션즈')은(는) 개인정보를 다음의 목적을 위해
          처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이
          변경될 시에는 사전동의를 구할 예정입니다.
        </p>
        <p>가. 기타 문의사항 입력시 연락망 구축 등을 목적으로 개인정보를 처리합니다.</p>
        <p>2. 개인정보 파일 현황 </p>
        <p>1. 개인정보 파일명 : CONTACT INFO.</p>
        <p>개인정보 항목 : 이메일, 휴대전화번호, 이름, 회사명</p>
        <p>수집방법 : 홈페이지 </p>
        <p>보유근거 : 문의사항 페이지 내 연락망 구축</p>
        <p> 보유기간 : 5년</p>
        <p>관련법령 : </p>
        <p>3. 개인정보의 처리 및 보유 기간</p>
        <p>
          ① {title}
          ('에프엠커뮤니케이션즈')은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
          개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.
        </p>
        <p>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
        <p>1.{'<기타>'}</p>
        <p>
          {'<기타>'}와 관련한 개인정보는 수집.이용에 관한 동의일로부터{'<5년>'}까지 위 이용목적을
          위하여 보유.이용됩니다.
        </p>
        <p>보유근거 : 문의사항 페이지 내 연락망 구축</p> <div>보관련법령 : </div>
        <p>예외사유 : </p>
        <p>4. 개인정보의 제3자 제공에 관한 사항 </p>
        <p>
          ① {title}('www.feelxmove.com'이하 '에프엠커뮤니케이션즈')은(는) 정보주체의 동의, 법률의
          특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
          제공합니다.
        </p>
        <p>
          ② {title}
          ('www.feelxmove.com')은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
        </p>
        <p>1. </p> <div>개인정보를 제공받는 자 :</div>
        <p>제공받는 자의 개인정보 이용목적 :</p>
        <p>제공받는 자의 보유.이용기간:</p>
        <p>5. 개인정보처리 위탁</p>
        <p>
          {' '}
          ①{title}('에프엠커뮤니케이션즈')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이
          개인정보 처리업무를 위탁하고 있습니다.
        </p>
        <p> 1. </p>
        <p>위탁받는 자 (수탁자) : </p>
        <p>위탁하는 업무의 내용 : </p>
        <p>위탁기간 : </p>
        <p>
          ② {title}
          ('www.feelxmove.com'이하 '에프엠커뮤니케이션즈')은(는) 위탁계약 체결시 개인정보 보호법
          제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한,
          수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고,
          수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
        </p>
        <p>
          ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여
          공개하도록 하겠습니다.
        </p>
        <p>
          6. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은
          권리를 행사할 수 있습니다.
        </p>
        <p>
          ① 정보주체는 에프엠커뮤니케이션즈에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구
          등의 권리를 행사할 수 있습니다.
        </p>
        <p>
          ② 제1항에 따른 권리 행사는에프엠커뮤니케이션즈에 대해 개인정보 보호법 시행령 제41조제1항에
          따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 에프엠커뮤니케이션즈은(는)
          이에 대해 지체 없이 조치하겠습니다.
        </p>
        <p>
          ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여
          하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을
          제출하셔야 합니다.
        </p>
        <p>
          ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여
          정보주체의 권리가 제한 될 수 있습니다.
        </p>
        <p>
          ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
          경우에는 그 삭제를 요구할 수 없습니다.
        </p>
        <p>
          ⑥ 에프엠커뮤니케이션즈은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
          처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
        </p>
        <p>7. 처리하는 개인정보의 항목 작성</p>{' '}
        <p>
          ① {title}
          ('www.feelxmove.com'이하 '에프엠커뮤니케이션즈')은(는) 다음의 개인정보 항목을 처리하고
          있습니다.
        </p>
        <p>1.{'<기타>'}</p>
        <p>필수항목 : 이메일, 휴대전화번호, 이름, 회사명</p>
        <p>- 선택항목 : 문의 내용 및 첨부 파일</p>
        <p>
          8. 개인정보의 파기{title}
          ('에프엠커뮤니케이션즈')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이
          해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
        </p>
        <p>-파기절차</p>
        <p>
          이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침
          및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진
          개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
        </p>
        <p>-파기기한</p>
        <p>
          이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일
          이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가
          불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그
          개인정보를 파기합니다.
        </p>
        <p>-파기방법</p>
        <p>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다</p>
        <p>9. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</p>
        <p>
          ① 에프엠커뮤니케이션즈 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로
          불러오는 ‘쿠기(cookie)’를 사용합니다.
        </p>
        <p>
          ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는
          소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        </p>
        <p>
          가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,
          인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
        </p>
        <p>
          나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구{'>'}인터넷 옵션{'>'}
          개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
        </p>
        <p>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>
        <p>10. 개인정보 보호책임자 작성</p>
        <p>
          ① 에프엠커뮤니케이션즈(‘www.feelxmove.com’이하 ‘에프엠커뮤니케이션즈) 은(는) 개인정보
          처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
          피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <p>▶ 개인정보 보호책임자</p> <p>성명 :조수연</p> <p>직책 :대표</p>
        <p>직급 :대표</p>
        <p>
          연락처 :02-3498-0800, webmaster@promotion.co.kr, ※ 개인정보 보호 담당부서로 연결됩니다.
        </p>
        <p>▶ 개인정보 보호 담당부서</p> <p>부서명 :미래커뮤니케이션본부</p>
        <p>담당자 :이중권</p> <p>연락처 :02-3498-0819, fmljk@promotion.co.kr,</p>
        <p>
          ② 정보주체께서는 에프엠커뮤니케이션즈(‘www.feelxmove.com’이하 ‘에프엠커뮤니케이션즈) 의
          서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제
          등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
          에프엠커뮤니케이션즈(‘www.feelxmove.com’이하 ‘에프엠커뮤니케이션즈) 은(는) 정보주체의
          문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
        </p>
        <p>11. 개인정보 처리방침 변경</p>
        <p>
          ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및
          정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
        </p>
        <p>12. 개인정보의 안전성 확보 조치</p>
        <p>
          {title}
          ('에프엠커뮤니케이션즈')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에
          필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
        </p>
        <p>
          1. 개인정보 취급 직원의 최소화 및 교육 개인정보를 취급하는 직원을 지정하고 담당자에
          한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
        </p>
        <p>
          2. 개인정보에 대한 접근 제한 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
          부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며
          침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
        </p>
        <p>
          3. 비인가자에 대한 출입 통제 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에
          대해 출입통제 절차를 수립, 운영하고 있습니다.
        </p>
      </DialogContent>
    </Dialog>
  );
}

export default PrivacyPoliticModal;
