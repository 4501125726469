import BusinessHeader from 'components/business/BusinessHeader';
import BusinessSubMenu from 'components/business/BusinessSubMenu';
import BusinessTextImage2 from 'components/business/BusinessTextImage2';
import { designInfo } from 'lib/values/businessInfo';
import React, { useState } from 'react';

const menuList = [
  { target: 'content0', title: 'About' },
  { target: 'content1', title: 'Branding' },
  { target: 'content2', title: 'UI/UX' },
  { target: 'content3', title: 'Character' },
  { target: 'content4', title: 'Product' },
  { target: 'content5', title: 'Contents' },
];

function Design() {
  const [focus, setFocus] = useState('content0');
  return (
    <div className="designWrapper noscolling">
      <BusinessHeader
        target="design"
        headerTitle={designInfo.headerTitle}
        subTitle={designInfo.subTitle}
        contents={designInfo.contents}
      />
      <div className="contentSection">
        <div className="switchingPage">
          <BusinessSubMenu menuList={menuList} focus={focus} />
          {designInfo.mainContents.map((data, idx) => (
            <BusinessTextImage2
              idx={idx}
              title={data.title}
              content={data.content}
              footer={data.footer}
              images={data.images}
              target="design"
              setFocus={setFocus}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Design;
