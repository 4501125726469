import usePortfolio from 'hooks/portfolio/usePortfolio';
import React, { useEffect, useState } from 'react';

function PortfolioSearch() {
  const { name, maintag, subtag, onGetPortfolioList } = usePortfolio();
  const [nameVal, setNameVal] = useState(name);

  useEffect(() => {
    setNameVal(name);
  }, [name]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNameVal(value);
  };

  const onSearch = () => {
    onGetPortfolioList({ maintag, subtag, name: nameVal, nowPage: 0 });
  };

  const onKeywordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    if (key === 'Enter') {
      onSearch();
    }
  };

  return (
    <div className="portfolioSearchWrapper">
      {' '}
      <input type="text" value={nameVal} onChange={onChange} onKeyDown={onKeywordKeyDown} />{' '}
      <button onClick={onSearch}>
        <img src="/img/search.svg" alt="seacrh" />
      </button>
    </div>
  );
}

export default PortfolioSearch;
