import client from 'lib/client';

export function doLogin({ response }: { response: any }) {
  const clientRes = client.post('/login', JSON.stringify(response));
  return clientRes;
}

export function doLogout() {
  const clientRes = client.get('/logout');
  return clientRes;
}

export function sendContact({
  to,
  name,
  company,
  email,
  phone,
  text,
  file,
}: {
  to: string;
  name: string;
  company: string;
  email: string;
  phone: string;
  text: string;
  file?: File;
}) {
  let formData = new FormData();
  formData.append('to', to);
  formData.append('name', name);
  formData.append('company', company);
  formData.append('email', email);
  formData.append('phone', phone);
  formData.append('text', text);
  file && formData.append('file', file);
  const clientRes = client.post('/contact', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return clientRes;
}
