import useOnScreen from 'lib/useOnScreen';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Element } from 'react-scroll';
import { PortfolioInfo } from 'store/portfolio/types';
import gsap from 'gsap';
import DefaultImage from 'css/img/concept_art.jpg';
import { push } from 'lib/historyUtils';

interface HybridEventPageProps {
  target: string;
  title: Array<string>;
  subtitle1: Array<string>;
  // subtitle2: Array<string>;
  description: Array<string>;
  setFocus: (focus: string) => void;
  portfolioList: Array<PortfolioInfo>;
  onClick: (id: string) => void;
}

function HybridEventPage({
  target,
  title,
  subtitle1,
  // subtitle2,
  description,
  setFocus,
  portfolioList,
  onClick,
}: HybridEventPageProps) {
  const [selected, setSelected] = useState(portfolioList.length > 0 ? portfolioList[0] : undefined);

  const divRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(divRef, '0px', 0.3);

  const tween = gsap.timeline({ paused: true });
  const [animation, setAnimation] = useState<any>(null);

  const onSelectImage = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget;
    setSelected(portfolioList.filter((data) => id === data._id)[0]);
  };

  const onClickPortfolio = () => {
    selected && onClick(selected._id);
  };

  useEffect(() => {
    !selected && portfolioList.length > 0 && setSelected(portfolioList[0]);
  }, [portfolioList]);

  useEffect(() => {
    if (onScreen) {
      setFocus(target);
      animation.play();
    }
  }, [onScreen]);

  useEffect(() => {
    if (titleRef.current && imageRef.current) {
      setAnimation(
        tween
          .to(titleRef.current, { y: -20, autoAlpha: 1 })
          .to(imageRef.current, { y: -20, autoAlpha: 1 })
          .pause()
      );
    }
  }, [titleRef.current, imageRef.current]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: portfolioList.length > 4 ? 5 : portfolioList.length,
    slidesToScroll: portfolioList.length > 4 ? 5 : portfolioList.length,
    // 포트폴리오 미니멈 숫자보다 포트 폴리오 갯수가 적을시 두개의 slider 가 생성됨 ㅜ
  };

  const moveToPortfolio = () => {
    push('/portfolio?tag1=hybridevent&tag2=' + target);
  };

  return (
    <Element name={target}>
      <div className="contentWrapper" ref={divRef}>
        <div className="descriptionWrapper" ref={titleRef}>
          <div className="title">
            {title.map((data) => (
              <Fragment key={data}>
                {data}
                <br />
              </Fragment>
            ))}
          </div>
          <div className="content">
            {subtitle1.map((data) => (
              <Fragment key={data}>
                {data}
                <br />
              </Fragment>
            ))}
            {/* <br />
            {subtitle2.map((data) => (
              <Fragment key={data}>
                {data}
                <br />
              </Fragment>
            ))} */}
          </div>
          <div className="content footer">
            {description.map((data) => (
              <Fragment key={data}>
                {data}
                <br />
              </Fragment>
            ))}
            <a onClick={moveToPortfolio}>SEE MORE</a>
          </div>
        </div>
        <div className={'refImgWrapper'} ref={imageRef}>
          <div onClick={onClickPortfolio} className="main">
            <img src={selected ? selected.thumbnail.src : DefaultImage} alt="reference" />
            <div className="mainAction">
              <div className="contentWrapper">
                <div className="title">{selected ? selected.name : ''}</div>
                <span>
                  <img src="/img/link.svg" alt="link" />
                </span>
              </div>
            </div>
          </div>
          <div className="imgsWrapper">
            <Slider {...settings}>
              {portfolioList.map((data) => (
                <div onClick={onSelectImage} id={data._id} key={data._id} className="slick">
                  <img src={data.thumbnail.src} alt="references" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default HybridEventPage;
