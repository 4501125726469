import { CANCEL_LOADING, SET_HEADER_COLOR, SET_LOADING } from './types';

export const setLoading = () => ({
  type: SET_LOADING,
});

export const cancelLoading = () => ({
  type: CANCEL_LOADING,
});

export const setHeaderColor = ({ headerColor }: { headerColor: string }) => ({
  type: SET_HEADER_COLOR,
  headerColor,
});
