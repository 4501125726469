import {
  User,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  OPEN_USER_MODAL,
  CLOSE_USER_MODAL,
} from './types';
import { UserActionTypes } from './actionTypes';

/** initial state */
const initialState: User = {
  userModal: false,
};

const userReducer = (state = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        userModal: false,
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
      };
    case OPEN_USER_MODAL:
      return {
        ...state,
        userModal: true,
      };
    case CLOSE_USER_MODAL:
      return {
        ...state,
        userModal: false,
      };

    default:
      return state;
  }
};

export default userReducer;
