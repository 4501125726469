import React from 'react';

interface BusinessAreaProps {
  name: string;
  content1: string;
  content2: string;
  area: Array<string>;
}

function BusinessArea({ name, content1, content2, area }: BusinessAreaProps) {
  return (
    <div className="singleDept">
      <div className="deptName">{name}</div>
      <div className="deptDescription first">{content1}</div>
      <div className="deptDescription">{content2}</div>
      <div className="deptDetail">
        {area.map((data) => (
          <span key={data}>{data} </span>
        ))}
      </div>
    </div>
  );
}

export default BusinessArea;
