import BusinessArrow from 'components/common/BusinessArrow';
import React, { useEffect, useRef } from 'react';

interface BusinessHeaderProps {
  target: string;
  headerTitle: Array<string>;
  subTitle: Array<{ content: string; element: string; className: string }>;
  contents: Array<{ content: string; element: string; className: string }>;
}

function BusinessHeader({ target, headerTitle, subTitle, contents }: BusinessHeaderProps) {
  const sectionRef = useRef<HTMLDivElement>(null);

  const headerRef = useRef<HTMLDivElement>(null);
  const subRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const onFadeInUp = (e: any) => {
    const { animationName } = e;

    if (animationName === 'fadeInUp') {
      contentRef.current && contentRef.current.classList.add('show');
    }
  };

  useEffect(() => {
    headerRef.current && headerRef.current.classList.add('show');
    subRef.current && subRef.current.classList.add('show');
    headerRef.current && headerRef.current.addEventListener('animationend', onFadeInUp);
  }, []);

  return (
    <>
      <div className="headSection" ref={sectionRef}>
        <div className="headTitle" ref={headerRef}>
          {headerTitle.map((data) => (
            <React.Fragment key={data}>
              {data}
              <br />
            </React.Fragment>
          ))}
        </div>
        <div className="subTitle" ref={subRef}>
          {subTitle.map((data) =>
            data.element === 'span' ? (
              <React.Fragment key={data.content}>
                <span className={data.className}>{data.content}</span>
                <br />
              </React.Fragment>
            ) : (
              <React.Fragment key={data.content}>
                {data.content}
                <br />
              </React.Fragment>
            )
          )}
        </div>
        {contents.length > 0 && (
          <div
            className="content"
            // className={
            //   `content ${target === 'hybrid' || target === 'originalIp' || target === 'vStudio' ? 'small' : ''}`
            // }
            ref={contentRef}
          >
            {contents.map((data) =>
              data.element === 'span' ? (
                <React.Fragment key={data.content}>
                  <span className={data.className}>{data.content}</span>
                  <br />
                </React.Fragment>
              ) : (
                <React.Fragment key={data.content}>
                  {data.content}
                  <br />
                </React.Fragment>
              )
            )}
          </div>
        )}
      </div>
      <BusinessArrow path={target} />
    </>
  );
}

export default BusinessHeader;
