import useOnScreen from 'lib/useOnScreen';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import '../css/about.scss';
import DareImg from '../css/img/dare_img.svg';
import RockImg from '../css/img/about_rocks.png';
import LineFront from '../css/img/imagine_front.gif';
import LineBack from '../css/img/imagine_back.gif';

import DreamImg from '../css/img/dream_img.svg';
import DreamLineFront from '../css/img/imagine_dream_front.gif';
import DreamLineBack from '../css/img/imagine_dream_back.gif';
import DreamTextFront from '../css/img/dream_text_front.gif';
import DreamTextBack from '../css/img/dream_text_back.gif';
import BlackBackground from '../css/img/bg_white_line.png';
import useCommon from 'hooks/common/useCommon';

import { businessAreaList } from '../lib/values/portfolioValues';
import BusinessArea from 'components/about/BusinessArea';
import gsap from 'gsap';
import { logoImageList } from 'lib/values/logoImageList';
import BrandStoryModal from 'components/about/BrandStoryModal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';
import BusinessArrow from 'components/common/BusinessArrow';

function About() {
  const { onSetHeaderColor } = useCommon();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [scrollShow, setScrollShow] = useState(true);
  const [arrowColor, setArrowColor] = useState('black');
  const [overflow, setOverflow] = useState('hidden');
  const [scrollTime, setScrollTime] = useState(0);
  const scrollTimeRef = useRef<any>(0);

  const section1Ref = useRef<HTMLDivElement>(null);
  const section6Ref = useRef<HTMLDivElement>(null);
  const section7Ref = useRef<HTMLDivElement>(null);
  const section8Ref = useRef<HTMLDivElement>(null);

  const section1ContentRef = useRef<HTMLDivElement>(null);
  const section2ContentRef = useRef<HTMLDivElement>(null);
  const section3ContentRef = useRef<HTMLDivElement>(null);
  const section5ContentRef = useRef<HTMLDivElement>(null);

  const section1_onscreen = useOnScreen(section1Ref);
  const section6_onscreen = useOnScreen(section6Ref);
  const section7_onscreen = useOnScreen(section7Ref);
  const section8_onscreen = useOnScreen(section8Ref);

  const dareRef = useRef<HTMLImageElement>(null);
  const lineFrontRef = useRef<HTMLImageElement>(null);

  const dreamRef = useRef<HTMLImageElement>(null);
  const backgroundRef = useRef<HTMLImageElement>(null);
  const backgroundRockRef = useRef<HTMLImageElement>(null);

  const setColorToWhite = () => {
    onSetHeaderColor({ headerColor: 'white' });
  };

  const setColorToBlack = () => {
    onSetHeaderColor({ headerColor: 'black' });
  };

  const [animation1, setAnimation1] = useState<any>(null);
  const [animation2, setAnimation2] = useState<any>(null);
  const [animation3, setAnimation3] = useState<any>(null);
  const [animation4, setAnimation4] = useState<any>(null);
  const [animation5, setAnimation5] = useState<any>(null);

  const [useWheel, setUseWheel] = useState(true);

  const fliperRef = useRef<Array<HTMLDivElement | null>>([]);
  const [fliped, setFliped] = useState(false);

  const [clientY, setClientY] = useState(0);

  useEffect(() => {
    gsap.timeline().to(section1ContentRef.current, { y: -20, autoAlpha: 1 });
    setAnimation1(
      gsap
        .timeline({
          paused: true,
        })
        .to(section1ContentRef.current, { y: -20, autoAlpha: 0 })
        .to(dareRef.current, { autoAlpha: 1, scale: 1 })
        .to(section2ContentRef.current, { y: -20, autoAlpha: 1 })
        .pause()
    );

    setAnimation2(
      gsap
        .timeline({
          paused: true,
        })
        .to(lineFrontRef.current, { y: -20, autoAlpha: 1 })
        .to(section3ContentRef.current, { y: -20, autoAlpha: 1 })
        .pause()
    );
    setAnimation3(
      gsap
        .timeline({
          paused: true,
        })
        .to([section2ContentRef.current, section3ContentRef.current], {
          y: -20,
          autoAlpha: 0,
          stagger: 0.2,
        })
        .to([lineFrontRef.current, dareRef.current], { autoAlpha: 0, stagger: 0.2 })
        .to(dreamRef.current, { autoAlpha: 1 }, '<')
        .to(backgroundRef.current, { autoAlpha: 1 }, '<')
        .to(dreamRef.current, { scale: 1.25 })
        .pause()
    );

    setAnimation4(
      gsap
        .timeline({
          paused: true,
        })
        .to(dreamRef.current, { scale: 1 })
        .to(dreamRef.current, { autoAlpha: 0 })
        .pause()
      // .to(section5ContentRef.current, { autoAlpha: 1 })
    );

    setAnimation5(
      gsap
        .timeline({
          paused: true,
        })
        .to(backgroundRef.current, { autoAlpha: 0 })
        .to(backgroundRockRef.current, { autoAlpha: 0 }, '<')
        .pause()
    );
  }, []);

  const handleScroll = (data: number, isBackward: boolean) => {
    if (!isBackward && data === 4) {
      setUseWheel(false);
    } else {
      setTimeout(() => {
        setUseWheel(true);
      }, 500);
    }

    scrollTimeRef.current = data;
    setScrollTime(data);
  };

  const onChangeScroll = (deltaY: number) => {
    setUseWheel(false);
    let data = deltaY < 0 ? scrollTimeRef.current - 1 : scrollTimeRef.current + 1;
    data = data < 0 ? 0 : data > 4 ? 4 : data;
    const isBackward = deltaY >= 0 ? false : true;
    if (data === 1 && !isBackward) {
      animation1 &&
        animation1.play().then(() => {
          handleScroll(data, isBackward);
        });
    } else if (data === 0 && isBackward) {
      animation1 &&
        animation1.reverse().then(() => {
          handleScroll(data, isBackward);
        });
    } else if (data === 1 && isBackward) {
      animation2 &&
        animation2.reverse().then(() => {
          handleScroll(data, isBackward);
        });
    } else if (data === 2 && !isBackward) {
      animation2 &&
        animation2.play().then(() => {
          handleScroll(data, isBackward);
        });
    } else if (data === 2 && isBackward) {
      setColorToBlack();
      setArrowColor('black');
      animation3 &&
        animation3.reverse().then(() => {
          handleScroll(data, isBackward);
        });
    } else if (data === 3 && !isBackward) {
      setColorToWhite();
      setArrowColor('white');
      animation3 &&
        animation3.play().then(() => {
          handleScroll(data, isBackward);
        });
    } else if (data === 3 && isBackward) {
      animation4 &&
        animation4.reverse().then(() => {
          handleScroll(data, isBackward);
          setOverflow('hidden');
        });
    } else if (data === 4 && !isBackward) {
      animation4 &&
        animation4.play().then(() => {
          handleScroll(data, isBackward);
          setOverflow('');
        });
    }
  };

  const onWheel = (e: any) => {
    if (e) {
      const deltaY = e.deltaY;
      onChangeScroll(deltaY);
    }
  };

  const onTouchStart = (e: any) => {
    if (e) {
      setClientY(e.touches[0].clientY);
    }
  };

  const onTouchEnd = (e: any) => {
    if (e) {
      const deltaY = clientY - e.changedTouches[0].clientY;
      onChangeScroll(deltaY);
    }
  };
  useEffect(() => {
    if (section1_onscreen) {
      setUseWheel(true);
    } else {
      setUseWheel(false);
    }
    if (section7_onscreen) {
      setArrowColor('black');
      setColorToBlack();
      animation5.play();
      setScrollShow(true);
    } else if (section6_onscreen) {
      setArrowColor('white');
      setColorToWhite();
      animation5.reverse();
      setScrollShow(true);
    } else if (section8_onscreen) {
      setScrollShow(false);
      setArrowColor('black');
      setColorToBlack();
      animation5.play();
    }
  }, [section6_onscreen, section7_onscreen, section8_onscreen, section1_onscreen]);

  const onClickOpen = () => {
    setOpen(!open);
  };

  const flip = () => {
    fliperRef.current &&
      fliperRef.current.map((data) => {
        if (data) {
          console.log(navigator.userAgent);
          if (navigator.userAgent.indexOf('iPhone') === -1) {
            const orientation = fliped ? 360 : 180;
            data.style.transform = `rotate(${360 - orientation}deg)`;
          } else {
            data.style.transform = `rotateY(${fliped ? 360 : 180}deg)`;
          }
        }
      });
    setFliped(!fliped);
  };

  const onHandloeModal = () => {
    console.log(modal);
    setModal(!modal);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Wrapper
        className="about"
        scrolling={overflow}
        onWheel={useWheel ? onWheel : undefined}
        onTouchStart={useWheel ? onTouchStart : undefined}
        onTouchEnd={useWheel ? onTouchEnd : undefined}
      >
        <div className="rock_background">
          <img src={RockImg} alt="rocks" ref={backgroundRockRef} />
          <img src={BlackBackground} alt="background" className="background" ref={backgroundRef} />
        </div>
        <div className="fixed_material">
          <div className="empty" ref={section1Ref}>
            &nbsp;
          </div>
          <div className="section1 contents" ref={section1ContentRef}>
            에프엠커뮤니케이션즈는 <br />
            <span className="red">감히 상상하라</span>를 바탕으로 <br />
            콘텐츠를 통해 <br />
            <span className="red">누구나 꿈꾸는 세상</span>을<br />
            만들어 갑니다.
          </div>
          <div className="section2 contents" ref={section2ContentRef}>
            <div className="header">Core Value</div>
            <div className="detail">
              새로운 아이디어를 <br />
              실현하는 창의적 <br />
              도전 정신
            </div>
          </div>
          <div className="dare_section">
            <img src={DareImg} alt="dare" className="dare" ref={dareRef} />
          </div>
          <div className="dare_section line" ref={lineFrontRef}>
            <img src={LineFront} alt="front" className="front" />
            <img src={LineBack} alt="back" className="back" />
          </div>
          <div className="section3 contents" ref={section3ContentRef}>
            <div>
              세상을 변화시키는
              <br />
              끊임없는 상상
            </div>
          </div>
          <div className="dream_section" ref={dreamRef}>
            <img src={DreamTextFront} alt="front" className="front" />
            <img src={DreamLineFront} alt="front" className="front" />
            <img src={DreamImg} alt="dream" className="dream" />
            <img src={DreamLineBack} alt="back" className="back" />
            <img src={DreamTextBack} alt="back" className="back" />
          </div>
        </div>
        <section id="section_brandstroy">
          <div className="contents" ref={section5ContentRef}>
            <div className="title">Brand Story</div>
            <div className="brandStoryWrapper">
              <div className="iframeWrapper">
                <iframe
                  src="https://www.youtube.com/embed/PlMbKkb4Z-o?&playsinline=1&controls=1&rel=0&showinfo=0&modestbranding=1"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="link" onClick={onHandloeModal}>
                SEE MORE <img src="/img/link.svg" alt="link" />
              </div>
            </div>
          </div>
        </section>
        <section id="section_5">
          <div className="contents">
            <div className="title">Business Area</div>
            <div className="description">
              에프엠커뮤니케이션즈는 문화와 기술을 융합한 <br />‘
              <span className="red">하이브리드 문화 기술 콘텐츠</span>’를 통해 <br />
              <span className="red">온·오프라인</span> 모든 접점에서 <br />
              <span className="red">브랜드 경험</span>을 만듭니다.
            </div>
            <div className="deptInfo">
              <div className="operationDept">
                {businessAreaList.map((data) => (
                  <BusinessArea
                    key={data.name}
                    name={data.name}
                    content1={data.content1}
                    content2={data.content2}
                    area={data.area}
                  />
                ))}
              </div>
              <div className="labDept">
                <div className="deptName">FM LAB(기업연구소)</div>
                <div className="deptDescription first">
                  문화콘텐츠와 기술분야의 연구를
                  <br />
                  통한 하이브리드 문화 기술 <br />
                  콘텐츠 개발
                </div>
                <div className="deptDetail">
                  HIGH IMAGINE LAB <br />
                  UNTACT TECH LAB
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section_6">
          <div className="empty" ref={section6Ref}>
            &nbsp;
          </div>
          <div className="title">Business Area</div>
          <div className="contentsWrapper">
            <div className="valueWrapper">
              <span>HYBRID</span>
              <span>CULTURE</span>
              <span>TECHNOLOGY</span>
            </div>
            <div className="mission">
              IP, 문화 예술 분야의 모든 콘텐츠와 <br />
              IT, 3D 그래픽 기술을 융합하여 <br />
              <span>종합적인 브랜드 경험</span>을 선사합니다.
            </div>
            {/* <div className="circles">
              <img src="/img/circles_white.svg" alt="circles" />
            </div>
            <div className="vision">
              Total <br />
              Brand Experience
            </div> */}
          </div>
        </section>
        <section id="section_7">
          <div className="empty" ref={section7Ref}>
            &nbsp;
          </div>
          <div className="title">
            History <br /> Since 1989
          </div>
          <div className="historyWrapper">
            <div className="history">
              우리는 다양한 방식으로 대중에게 <br />
              브랜드 스토리를 전해왔습니다.
            </div>
            <History className="historyList" open={open}>
              <table>
                <tbody>
                  <tr>
                    <td className="year">2020</td>
                    <td className="content">
                      <span>VORDERLESS STUDIO 개관</span>
                      <span>FM 연구소 등록 (언텍트 기술 연구소 / HIGH IMAGINE LAB)</span>
                      <span>온라인 이벤트 솔루션 B.BLOCK 출시</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2019</td>
                    <td className="content">
                      <span>해피 인사이드 전시</span>
                      <span>삼성 전자 연간 대행</span>
                      <span>제일 기획 연간 대행</span>
                      <span>아디다스 코리아 연간 대행</span>
                      <span>넥슨 연간 대행</span>
                      <span>롯데월드 몰 연간 대행</span>
                      <span>그라운드 X 연간 대행</span>
                      <span>뉴스킨 코리아 연간 대행</span>
                      <span>BMW KKL이벤트 연간 대행</span>
                      <span>생명존중 자살 예방 캠페인 연간 대행</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2018</td>
                    <td className="content">
                      <span>평창 동계 올림픽대회 개폐회식 운영</span>
                      <span>FM TECH 자회사 설립</span>
                      <span>대한 상공 회의소 발표 '일하기 좋은 중소기업 2018 선정'</span>
                      <span>
                        IF DESIGN AWARD 2018 커뮤니케이션 부분 수상 [Naver Creater day VOL.2]
                      </span>
                      <span>르코르 뷔지에 롱샹 성당 360 VR영상 저작권 등록</span>
                      <span>다중 객체 추적 시스템 및 그 방법 특허 등록</span>
                      <span>하이아이스크림 전시 (IP 사업 확장)</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2016</td>
                    <td className="content">
                      <span>아시아 태평양 스타비어워즈 이벤트 혁신금상 (GOLD STEIVE) 수상</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2014</td>
                    <td className="content">
                      <span>Hyundai Fan Park Gangnam</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2013</td>
                    <td className="content">
                      <span>삼성전자 멤버쉽 블루 연간 대행사 (2009~2013)</span>
                      <span>세븐럭 연간 대행사</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2012</td>
                    <td className="content">
                      <span>대한민국 광고대상 프로모션 부분 금상 수상</span>
                      <span>
                        제 49회 무역의 날 백만 불 수출의 탑 수상 (여수엑스포, 중국 두만강 문화 관광
                        축제)
                      </span>
                      <span>여수 세계 엑스포 개폐막식, 국제관 (알제리관, 벨기에관)</span>
                      <span>F1 코리아 그랑프리 대회</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2011</td>
                    <td className="content">
                      <span>F1 코리아 그랑프리 대회</span>
                      <span>한 · 중 최초 이벤트 콘텐츠 수출 (중국 두만강 문화 관광 축제)</span>
                      <span>재규어/랜드로버 연간 대행사</span>
                      <span>메트라이트 생명 연간 대행사</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2010</td>
                    <td className="content">
                      <span>울진 세계 웅기문화엑스포</span>
                      <span>웅진 코웨이 연간 대행사 선정</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2009</td>
                    <td className="content">
                      <span>제 2대 대표이사 심재길 대표 취임</span>
                      <span>인천 세계 도시축전 멀티미디어쇼</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2008</td>
                    <td className="content">
                      <span>한국이벤트대상 전시 부문 최우수상 수상</span>
                      <span>제일기획 2007년 The Best Partner 및 2차 Good Partner 선정</span>
                      <span>건국 60주년 기념 큰 울림 한강 축제</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2007</td>
                    <td className="content">
                      <span>경영 혁신 형 중소기업 선정</span>
                      <span>한국 디자인 진흥원 공공 환경 개선사업 당선</span>
                      <span>충남 개발 공사와 인천 지하철 광고 사업 공동 시행 협약 체결</span>
                      <span>GKL, 세븐럭 카지노 연간 대행 선정</span>
                      <span>한국 타이어 글로벌 모터 스포츠 부분 연간 대행사 선정</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2006</td>
                    <td className="content">
                      <span>서초동 사옥 이전</span>
                      <span>KOTRA 중소기업 글로벌 브랜드 컨설팅 기업 선정</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2005</td>
                    <td className="content">
                      <span>APEC 정상 만찬 문화 공연</span>
                      <span>증권 선물 거래소 연간 프로모션 대행사 선정</span>
                      <span>한국 디자인 진흥원 산업 디자인 전문회사 등록 (환경 디자인 부분)</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2004</td>
                    <td className="content">
                      <span>한국 나이키 연간 프로모션 대행사</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2003</td>
                    <td className="content">
                      <span>MMA(구 APMA, 세계 마케팅 대행사 협회) 아시아지역 은상 수상</span>
                      <span>Promotion Marketing Awards of Asia 2002 은상 수상</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2001</td>
                    <td className="content">
                      <span>(주) 에프엠커뮤니케이션즈로 사명 변경</span>
                      <span>Promotion Marketing oh Asia 2001-2개 부분 은상 수상</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">2000</td>
                    <td className="content">
                      <span>
                        업계 최초 ISO 9001 인증 획득 / 국내 최초 APMA (세계 프로모션 마케팅 대행사
                        협회) 등록
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">1999</td>
                    <td className="content">
                      <span>(주) 퓨처매니지먼트 커뮤니케이션즈로 사명 변경</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="year">1989</td>
                    <td className="content">
                      <span>(주) 에프엠 엔터프라이즈 설립 (5월 8일, 대표이사 조수연)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </History>
            <button className="historyMore" onClick={onClickOpen}>
              {open ? 'LESS' : 'SEE MORE'}
            </button>
          </div>
        </section>
        <section id="section_8">
          <div className="empty" ref={section8Ref}>
            &nbsp;
          </div>
          <div className="title">Partners</div>
          <div className="partnersWrapper">
            <div className="partnerPage">
              <Slider {...settings}>
                <>
                  {logoImageList.map((data, idx) => (
                    <div className="partner" key={'front' + idx}>
                      <img src={data.front} alt="1" />
                    </div>
                  ))}
                </>
                <>
                  {logoImageList.map((data, idx) => (
                    <div className="partner" key={'back' + idx}>
                      <img src={data.back} alt="1" />
                    </div>
                  ))}
                </>
              </Slider>
            </div>
            {/* <FlipedButton fliped={fliped} className="flip" onClick={flip}></FlipedButton> */}
          </div>
        </section>
      </Wrapper>
      {modal && <BrandStoryModal open={modal} onClose={onHandloeModal} />}
      {scrollShow && <BusinessArrow path={arrowColor} />}
    </>
  );
}

interface HistoryProps {
  open: boolean;
}

const History = styled.div<HistoryProps>`
  height: ${({ open }) => (open ? `1500px` : `300px`)};
  transition: all 0.6s ease;
  position: relative;
  &::after {
    content: '';
    opacity: ${({ open }) => (open ? `0` : `1`)};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0) 100%);
  }
`;

interface FlipedButton {
  fliped: boolean;
}
const FlipedButton = styled.button<FlipedButton>`
  &::before {
    opacity: ${({ fliped }) => (fliped ? `0.3` : `0.8`)};
  }

  &::after {
    opacity: ${({ fliped }) => (fliped ? `0.8` : `0.3`)};
  }
`;

interface WrapperProps {
  scrolling: string;
}

const Wrapper = styled.div<WrapperProps>`
  overflow: ${({ scrolling }) => scrolling};
  position: relative;
`;

export default About;
