import { deletePopup, getPopupList, insertPopup, updatePopup } from 'lib/api/popupApi';
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import { handleError } from 'store/common/sagas';
import {
  DeletePopupRequest,
  InsertPopupRequest,
  ListPopupRequest,
  UpdatePopupRequest,
} from './actionTypes';
import {
  DELETE_POPUP_REQUEST,
  INSERT_POPUP_REQUEST,
  LIST_POPUP_REQUEST,
  LIST_POPUP_SUCCESS,
  POPUP_FAILURE,
  UPDATE_POPUP_REQUEST,
} from './types';

function* popupList({ target, nowPage }: ListPopupRequest) {
  try {
    const clientRes = yield call(getPopupList, { target, nowPage });
    const { status, data } = clientRes;

    if (status === 200) {
      yield put({
        type: LIST_POPUP_SUCCESS,
        popupList: data.list,
        nowPage,
        totalCount: data.totalCount,
      });
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: POPUP_FAILURE,
    });
  }
}

function* popupInsert({ addImg, url, expire }: InsertPopupRequest) {
  try {
    const clientRes = yield call(insertPopup, { addImg, url, expire });
    const { status } = clientRes;

    if (status === 200) {
      yield call(popupList, { target: 'admin', nowPage: 0 } as ListPopupRequest);
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: POPUP_FAILURE,
    });
  }
}

function* popupUpdate({ addImg, img, url, expire, _id }: UpdatePopupRequest) {
  try {
    const clientRes = yield call(updatePopup, { addImg, img, url, expire, _id });
    const { status } = clientRes;
    if (status === 200) {
      yield call(popupList, { target: 'admin', nowPage: 0 } as ListPopupRequest);
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: POPUP_FAILURE,
    });
  }
}

function* popupDelete({ _id }: DeletePopupRequest) {
  try {
    const clientRes = yield call(deletePopup, { _id });
    const { status } = clientRes;

    if (status === 200) {
      yield call(popupList, { target: 'admin', nowPage: 0 } as ListPopupRequest);
    }
  } catch (e) {
    yield call(handleError, e);
    yield put({
      type: POPUP_FAILURE,
    });
  }
}

function* setPopupRequest() {
  yield takeLatest(LIST_POPUP_REQUEST, popupList);
  yield takeLatest(INSERT_POPUP_REQUEST, popupInsert);
  yield takeLatest(UPDATE_POPUP_REQUEST, popupUpdate);
  yield takeLatest(DELETE_POPUP_REQUEST, popupDelete);
}
export default function* popupSaga() {
  yield all([setPopupRequest()]);
}
